import { AppStoreProps } from '@@redux/index';
import { StageProps, WaypointProps } from '@@redux/reducers/roadbook';
import { Box, Typography, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import Styles from './styles.module.scss';
import { convertWaypoint, setHoveredWaypointUuid } from '@@redux/actions/roadbook';
import { useCallback } from 'react';
import { formatDistance } from '@@utils/functions/formatDistance';
import { useIntl } from 'react-intl';
import { createSVG } from '@@utils/functions/createSvg';
import { contrastColor } from 'contrast-color';

import { WAYPOINT_TYPE } from '@@utils/constants';
import { formatBearingAngle } from '@@utils/functions/formatBearingAngle';

export interface WaypointItemProps extends WaypointProps {
	index: number;
	stageColor: StageProps['color'];
}

const WaypointItem = ({
	uuid,
	stageColor,
	stageUuid,
	distanceToLast,
	distanceToLastWaypoint,
	distanceToStart,
	type,
	index,
	bearingAngle,
}: WaypointItemProps): JSX.Element => {
	const dispatch = useDispatch();
	const theme = useTheme();
	const intl = useIntl();

	const userDistanceUnit = useSelector((state: AppStoreProps) => state.user.distanceUnit);

	const isHovered = useSelector((state: AppStoreProps) =>
		!Array.isArray(state.roadbook.hoveredWaypointUuid)
			? state.roadbook.hoveredWaypointUuid === uuid
			: state.roadbook.hoveredWaypointUuid.includes(uuid));

	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
		id: uuid?.toString() as string,
	});

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	const onMouseOver = () => {
		dispatch(
			setHoveredWaypointUuid({
				uuid,
			})
		);
	};

	const onMouseOut = () => {
		dispatch(
			setHoveredWaypointUuid({
				uuid: null,
			})
		);
	};

	const formattedDistanceToStart = useCallback(
		(hideUnit?: boolean) => {
			if (typeof distanceToStart !== 'number') return undefined;
			return formatDistance({ intl, distance: distanceToStart, distanceUnit: userDistanceUnit, hideUnit });
		},
		[ distanceToStart, userDistanceUnit, intl, type ]
	);

	const formattedDistanceToLast = useCallback(
		(hideUnit?: boolean) => {
			if (typeof distanceToLast !== 'number') return undefined;
			return formatDistance({ intl, distance: distanceToLast, distanceUnit: userDistanceUnit, hideUnit });
		},
		[ distanceToLast, userDistanceUnit, intl, type ]
	);

	const formattedDistanceToLastWaypoint = useCallback(
		(hideUnit?: boolean) => {
			if (typeof distanceToLastWaypoint !== 'number') return undefined;
			return formatDistance({ intl, distance: distanceToLastWaypoint, distanceUnit: userDistanceUnit, hideUnit });
		},
		[ distanceToLastWaypoint, userDistanceUnit, intl, type ]
	);

	const getHoveredColor = useCallback(() => {
		return contrastColor({
			bgColor: stageColor,
		});
	}, [ isHovered, stageColor ]);

	const getIcon = useCallback(() => {
		return createSVG({
			icon: WAYPOINT_TYPE[ type ],
			width: 24,
			height: 24,
			backgroundColor: isHovered ? getHoveredColor() : stageColor,
			foregroundColor: isHovered ? stageColor : getHoveredColor(),
			toUrlString: true,
			withSvgHeader: true,
		});
	}, [ type, stageColor, isHovered ]);

	const getIconSize = useCallback(() => (isHovered ? 32 : 24), [ isHovered ]);

	const getIconStyle = useCallback(
		(position: 'left' | 'right') => {
			const iconSize = getIconSize() + 4;

			const style: {
				[key: string]: any;
			} = {
				width: iconSize,
				height: iconSize,
				borderRadius: iconSize / 2,
				position: 'absolute',
				top: iconSize / -2 - 2,
				border: '2px solid #000',
				cursor: 'pointer',
				zIndex: 1,
			};

			style[ position ] = iconSize / -2 - 2;

			return style;
		},
		[ isHovered ]
	);

	const onIconClick = useCallback(() => {
		if (![ WAYPOINT_TYPE.SHAPING_POINT, WAYPOINT_TYPE.WAYPOINT ].includes(type)) return;

		dispatch(
			convertWaypoint({
				uuid,
				type: type === WAYPOINT_TYPE.SHAPING_POINT ? WAYPOINT_TYPE.WAYPOINT : WAYPOINT_TYPE.SHAPING_POINT,
				stageUuid,
			})
		);
	}, [ type, stageUuid, uuid ]);

	return (
		<li
			ref={setNodeRef}
			key={uuid}
			className={Styles.WaypointItem}
			style={{
				...style,
				margin: `${theme.spacing(2)}`,
			}}
			{...attributes}
			{...listeners}
			onMouseOver={onMouseOver}
			onMouseOut={onMouseOut}
		>
			<Box component="span" sx={getIconStyle('left')} onMouseDown={onIconClick}>
				<img
					width={getIconSize()}
					height={getIconSize()}
					src={getIcon()}
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						zIndex: 2,
						pointerEvents: 'none',
					}}
				/>
			</Box>
			<Box component="span" className={Styles.DistanceData}>
				<Typography variant="h3" noWrap align="center" sx={{ margin: theme.spacing(2), overflow: 'visible' }}>
					{formattedDistanceToStart(true)}
				</Typography>
				<Box component="span" className={Styles.SubDistanceData}>
					<Box component="span" sx={{ padding: `${theme.spacing(1)} ${theme.spacing(2)}` }}>
						{formattedDistanceToLastWaypoint(true)}
					</Box>
					<Box component="span" sx={{ padding: `${theme.spacing(1)} ${theme.spacing(2)}` }}>
						{index + 1}
					</Box>
				</Box>
			</Box>
			<Box component="span" className={Styles.DistanceData}>
				icon here
			</Box>
			<Box component="span" className={Styles.NavigationData}>
				{formatBearingAngle({ intl, angleInDegrees: bearingAngle })}
			</Box>
		</li>
	);
};

export default WaypointItem;
