import { StageProps, WaypointProps } from '@@redux/reducers/roadbook';
import { Fragment } from 'react';
import Marker from '../Marker';
import PolylineWrapper from '../PolylineWrapper';

export interface StageWrapperProps extends StageProps {
	map: google.maps.Map | null;
}

const StageWrapper = ({ uuid, map, waypoints, visibleOnMap, active, color }: StageWrapperProps): JSX.Element | null => {
	if (!visibleOnMap || !active) return null;

	const waypointCount = (waypoints?.length || 0) - 1;

	const getKeyForPolyline = (waypointIndex: number): string | null => {
		if (!waypoints || waypointCount === 0) return null;

		return `PolylineWrapper-${uuid}-${waypoints[ waypointIndex ].uuid}-${
			waypoints[ waypointIndex + 1 ].uuid
		}-${waypointIndex}`;
	};

	return (
		<>
			{waypoints?.map((waypoint: WaypointProps, waypointIndex: number) => {
				return (
					<Fragment key={`Stage-${uuid}-Waypoints-${waypoint.uuid}`}>
						{waypointIndex < waypointCount && (
							<PolylineWrapper
								stageUuid={uuid}
								key={getKeyForPolyline(waypointIndex)}
								waypointIndexStart={waypointIndex}
								waypointUuidStart={waypoint.uuid}
								waypointUuidEnd={waypoints[ waypointIndex + 1 ].uuid}
								waypointIndexEnd={waypointIndex + 1}
								start={new google.maps.LatLng(waypoint.lat, waypoint.lng)}
								end={
									new google.maps.LatLng(
										waypoints[ waypointIndex + 1 ].lat,
										waypoints[ waypointIndex + 1 ].lng
									)
								}
								color={color}
								polylineProps={{
									map,
								}}
							/>
						)}
						<Marker
							key={`Marker-${uuid}-${waypoint.uuid}-${waypointIndex}`}
							{...waypoint}
							stageProps={{
								uuid,
								color,
								active,
								visibleOnMap,
							}}
							markerProps={{
								map,
							}}
						/>
					</Fragment>
				);
			})}
		</>
	);
};

export default StageWrapper;
