import { setSelectedStageUuid, toggleStageActive, toggleStageVisibleOnMap } from '@@redux/actions/roadbook';
import { StageProps } from '@@redux/reducers/roadbook';
import { DISTANCE_UNIT, ROADBOOK_SIDE_BAR_SETTINGS } from '@@utils/constants';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Checkbox, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ColorIconButton from '../ColorIconButton';
import DeleteIconButton from '../DeleteIconButton';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SettingsIcon from '@mui/icons-material/Settings';
import MapIcon from '@mui/icons-material/Map';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { AppStoreProps } from '@@redux/index';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { kilometersToMiles } from '@@utils/functions/kilometersToMiles';
import { formatDistance } from '@@utils/functions/formatDistance';

export interface StageDataGridItemProps extends StageProps {
	index: number;
	stageCount: number;
}

const StageDataGridItem = (props: StageDataGridItemProps): JSX.Element => {
	const { uuid, active, title, visibleOnMap, index, stageCount, distance } = props;

	const userDistanceUnit = useSelector((state: AppStoreProps) => state.user.distanceUnit);
	const intl = useIntl();
	const dispatch = useDispatch();
	const theme = useTheme();

	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
		id: uuid?.toString() as string,
	});

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	const formattedStageDistance = useCallback(() => {
		if (!distance) return undefined;
		return formatDistance({ intl, distance, distanceUnit: userDistanceUnit });
	}, [ distance, userDistanceUnit, intl ]);

	return (
		<ListItem
			ref={setNodeRef}
			key={uuid}
			style={{
				...style,
				paddingLeft: ROADBOOK_SIDE_BAR_SETTINGS.COLOR_INDICATOR_WIDTH,
			}}
			sx={{
				position: 'relative',
				opacity: active ? 1 : 0.75,
				borderTop: index > 0 ? `1px solid ${theme.palette.grey[ 200 ]}` : undefined,
				borderBottom: index < stageCount - 1 ? `1px solid ${theme.palette.grey[ 200 ]}` : undefined,
			}}
		>
			<ColorIconButton stage={props} />
			<ListItemIcon
				sx={{
					paddingLeft: theme.spacing(2),
				}}
			>
				<IconButton
					sx={{
						cursor: 'grab',
					}}
					{...attributes}
					{...listeners}
				>
					<DragIndicatorIcon />
				</IconButton>
			</ListItemIcon>
			<ListItemButton disabled={!active} onClick={() => dispatch(setSelectedStageUuid({ uuid }))}>
				<ListItemText primary={title} secondary={formattedStageDistance()} />
			</ListItemButton>
			<Checkbox
				icon={<CheckBoxOutlineBlankIcon style={{ color: theme.palette.text.secondary }} />}
				checkedIcon={<CheckBoxIcon style={{ color: theme.palette.text.secondary }} />}
				onClick={() => dispatch(toggleStageActive({ uuid }))}
				checked={active}
			/>
			<IconButton disabled={!active} onClick={() => dispatch(toggleStageVisibleOnMap({ uuid }))}>
				<MapIcon sx={{ opacity: visibleOnMap ? 1 : 0.5 }} />
			</IconButton>
			<ListItemIcon>
				<DeleteIconButton stage={props} />
				<IconButton disabled={!active} edge="end">
					<SettingsIcon />
				</IconButton>
			</ListItemIcon>
		</ListItem>
	);
};
export default StageDataGridItem;
