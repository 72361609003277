import { combineReducers, LiftedLoopReducer, reduceReducers } from 'redux-loop';
import AppState, { AppStateProps } from '@@redux/reducers/app';
import UserState, { UserStateProps } from '@@redux/reducers/user';
import RoadbookState, { RoadbookProps } from '@@redux/reducers/roadbook';
import { AnyAction } from 'redux';

export interface AppStoreProps {
	app: AppStateProps;
	user: UserStateProps;
	roadbook: RoadbookProps;
}

const AppStore: LiftedLoopReducer<AppStoreProps, AnyAction> = reduceReducers(
	combineReducers({
		app: AppState,
		user: UserState,
		roadbook: RoadbookState,
	})
);

export default AppStore;

// https://redux.js.org/usage/usage-with-typescript#define-root-state-and-dispatch-types
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof AppStore>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof AppStore.dispatch
