import { IntlShape } from 'react-intl';

export interface FormatBearingAngleProps {
	intl: IntlShape;
	angleInDegrees: number | null | undefined;
	hideUnit?: boolean;
}

export const formatBearingAngle = ({ intl, angleInDegrees, hideUnit }: FormatBearingAngleProps): string | null => {
	if (typeof angleInDegrees !== 'number') return null;

	let formattedNumber = intl.formatNumber(angleInDegrees || 0, {
		maximumFractionDigits: 0,
		minimumFractionDigits: 0,
	});

	if (!hideUnit) formattedNumber += '°';

	return formattedNumber;
};
