import { USER_ACTIONS } from '@@redux/actions/user';
import { IReduxActionProps } from '@@redux/utils';
import { DISTANCE_UNIT, SUPPORTED_LOCALES } from '@@utils/constants';

export interface UserStateProps {
	locale: SUPPORTED_LOCALES;
	distanceUnit: DISTANCE_UNIT;
	location?: {
		lat?: number | null;
		lng?: number | null;
		error?: boolean;
		fetching?: boolean;
	};
}

const InitialUserState: UserStateProps = {
	locale: SUPPORTED_LOCALES.DEFAULT,
	location: undefined,
	distanceUnit: DISTANCE_UNIT.DEFAULT,
};

const UserState = (state: UserStateProps = InitialUserState, action: IReduxActionProps) => {
	switch (action.type) {
		case USER_ACTIONS.SET_DISTANCE_UNIT:
			return {
				...state,
				distanceUnit: action.payload?.unit,
			} as UserStateProps;
		case USER_ACTIONS.SET_LOCALE:
			return {
				...state,
				locale: action.payload?.locale,
			} as UserStateProps;
		case USER_ACTIONS.SET_LOCATION:
			return {
				...state,
				location: {
					...state.location,
					lat: action.payload?.lat,
					lng: action.payload?.lng,
					error: false,
					fetching: false,
				},
			} as UserStateProps;
		case USER_ACTIONS.SET_LOCATION_ERROR:
			return {
				...state,
				location: {
					...state.location,
					lat: null,
					lng: null,
					error: action.payload?.error,
					fetching: false,
				},
			} as UserStateProps;
		case USER_ACTIONS.SET_LOCATION_FETCHING:
			return {
				...state,
				location: {
					...state.location,
					fetching: action.payload?.fetching,
				},
			} as UserStateProps;
		default:
			return state;
	}
};

export default UserState;
