import { AppBar as MaterialAppBar, IconButton, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useIntl } from 'react-intl';
import { translate } from '@@i18n/utils';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAppbarHeight } from '@@redux/actions/app';
import { debounce } from '@@utils/functions/debounce';
import { AppStoreProps } from '@@redux/index';
import SpeedIcon from '@mui/icons-material/Speed';
import LanguageSwitchButton from './LanguageSwitchButton';
import UserLocationButton from './UserLocationButton';
import DistanceUnitSwitchButton from './DistanceUnitSwitchButton';

const AppBar = (): JSX.Element => {
	const intl = useIntl();
	const dispatch = useDispatch();

	const [ windowResizeEventListener, setWindowResizeEventListener ] = useState<boolean>(false);

	const appbarRef = useRef(null);
	const roadbookSidebarOpen = useSelector((state: AppStoreProps) => state.app.roadbookSidebarOpen);
	const roadbookSidebarWidth = useSelector((state: AppStoreProps) => state.app.roadbookSidebarWidth);

	const onWindowResize = () => {
		if (appbarRef && appbarRef.current) {
			dispatch(
				setAppbarHeight({
					height: (appbarRef.current as HTMLElement).getBoundingClientRect().height,
				})
			);
		}
	};

	const onWindowResizeDebounced = debounce(onWindowResize, 50);

	useEffect(() => {
		if (!windowResizeEventListener) {
			setWindowResizeEventListener(true);
			window.addEventListener('resize', onWindowResizeDebounced);
			onWindowResize();
		}
	}, [ windowResizeEventListener ]);

	useEffect(() => {
		onWindowResize();
	}, [ roadbookSidebarWidth ]);

	return (
		<MaterialAppBar
			position="fixed"
			sx={{
				width: roadbookSidebarOpen ? `calc(100% - ${roadbookSidebarWidth}px)` : '100%',
				left: 0,
			}}
			ref={appbarRef}
		>
			<Toolbar>
				<IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
					<MenuIcon />
				</IconButton>
				<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
					{translate(intl, 'generic.app.title')}
				</Typography>
				<UserLocationButton />
				<DistanceUnitSwitchButton />
				<LanguageSwitchButton />
			</Toolbar>
		</MaterialAppBar>
	);
};

export default AppBar;
