import { useDispatch, useSelector } from 'react-redux';

import Styles from './styles.module.scss';
import { AppStoreProps } from '@@redux/index';
import { StageProps, WaypointProps } from '@@redux/reducers/roadbook';
import { UUID } from '@@utils/constants';
import WaypointItem from './WaypointItem';
import { getStageByUuid } from '@@utils/functions/getStageByUuid';

import { DndContext, DragEndEvent } from '@dnd-kit/core';

import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';

import { restrictToVerticalAxis, restrictToWindowEdges, restrictToParentElement } from '@dnd-kit/modifiers';
import { sortWaypointsForStageDragNDrop } from '@@redux/actions/roadbook';

const WaypointList = (): JSX.Element | null => {
	const dispatch = useDispatch();
	const stages = useSelector((state: AppStoreProps) => state.roadbook.stages);
	const selectedStageUuid = useSelector((state: AppStoreProps) => state.roadbook.selectedStageUuid);

	const filterStages = (stage: StageProps) => {
		let show = true;
		if (typeof selectedStageUuid === 'string' && stage.uuid !== selectedStageUuid) show = false;
		if (!stage.active) show = false;

		return show;
	};

	const filterWaypoints = (waypoint: WaypointProps) => true; //waypoint.type !== WAYPOINT_TYPE.SHAPING_POINT;

	const onGridDragEnd = (stageUuid: UUID, event: DragEndEvent) => {
		const { active, over } = event;

		if ((active.id as UUID) !== (over?.id as UUID)) {
			const stage = getStageByUuid({ uuid: stageUuid, stages });

			if (stage && Array.isArray(stage.waypoints) && stage.waypoints.length > 0) {
				let waypointUuids: UUID[] = stage.waypoints?.map((waypoint: WaypointProps) => waypoint.uuid);
				const oldIndex = waypointUuids.indexOf(active.id);
				const newIndex = waypointUuids.indexOf(over?.id);

				waypointUuids = arrayMove(waypointUuids, oldIndex, newIndex);

				dispatch(sortWaypointsForStageDragNDrop({ uuids: waypointUuids, stageUuid: stage?.uuid }));
			}
		}
	};

	return (
		stages && (
			<ol className={Styles.WaypointList}>
				{stages.filter(filterStages).map((stage: StageProps) => {
					const waypoints = stage.waypoints || [];

					return (
						<DndContext
							key={stage.uuid}
							onDragEnd={(event: DragEndEvent) => onGridDragEnd(stage.uuid, event)}
							modifiers={[ restrictToParentElement, restrictToVerticalAxis, restrictToWindowEdges ]}
						>
							<SortableContext
								items={waypoints.filter(filterWaypoints).map((waypoint: WaypointProps) => {
									return {
										id: waypoint.uuid?.toString() as string,
									};
								})}
								strategy={verticalListSortingStrategy}
							>
								{waypoints
									.filter(filterWaypoints)
									.map((waypoint: WaypointProps, waypointIndex: number) => {
										return (
											<WaypointItem
												stageColor={stage.color}
												{...waypoint}
												index={waypointIndex}
												key={waypoint.uuid}
											/>
										);
									})}
							</SortableContext>
						</DndContext>
					);
				})}
			</ol>
		)
	);
};

export default WaypointList;
