import { CircularProgress, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { setLocation, setLocationError, setLocationFetching } from '@@redux/actions/user';
import { AppStoreProps } from '@@redux/index';
import { Status } from '@googlemaps/react-wrapper';

const UserLocationButton = () => {
	const dispatch = useDispatch();
	const userLocationFetching = useSelector((state: AppStoreProps) => state.user.location?.fetching || false);
	const googleMapsReady = useSelector((state: AppStoreProps) => state.app.map.status === Status.SUCCESS);

	const getUserLocation = () => {
		if (userLocationFetching) return;

		try {
			dispatch(setLocationFetching({ fetching: true }));
			navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
				dispatch(
					setLocation({
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					})
				);
			}),
				(error: GeolocationPositionError) => {
					// eslint-disable-next-line no-console
					console.error(error);
					dispatch(setLocationError({ error: true }));
				},
				{
					enableHighAccuracy: true,
					timeout: 10000,
				};
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
			dispatch(setLocationError({ error: true }));
		}
	};

	return googleMapsReady ? (
		<IconButton
			size="large"
			aria-label="account of current user"
			aria-controls="language-menu"
			aria-haspopup="true"
			onClick={getUserLocation}
			color="inherit"
		>
			{!userLocationFetching && <MyLocationIcon />}
			{userLocationFetching && <CircularProgress color="inherit" size={20} thickness={6} />}
		</IconButton>
	) : null;
};

export default UserLocationButton;
