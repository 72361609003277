import { StageProps } from '@@redux/reducers/roadbook';

export const getStageByUuid = ({
	uuid,
	stages,
}: {
	uuid: StageProps['uuid'];
	stages: StageProps[];
}): StageProps | null => {
	let res = null;
	const tmp = stages.filter((stage: StageProps) => stage.uuid === uuid);
	if (tmp.length === 1) res = tmp[ 0 ];

	return res;
};
