import { translate } from '@@i18n/utils';
import { setRoadbookInformation, setRoadbookTitle } from '@@redux/actions/roadbook';
import { debounce } from '@@utils/functions/debounce';
import { Box, Grid, TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

const RoadbookMetaData = (): JSX.Element => {
	const intl = useIntl();
	const dispatch = useDispatch();

	const onTitleInputChange = (event: React.ChangeEvent<HTMLElement>) => {
		dispatch(setRoadbookTitle({ title: (event.target as HTMLInputElement).value }));
	};
	const onTitleInputChangeDebounced = debounce(onTitleInputChange, 250);

	const onInformationInputChange = (event: React.ChangeEvent<HTMLElement>) => {
		dispatch(setRoadbookInformation({ information: (event.target as HTMLInputElement).value }));
	};
	const onInformationInputChangeDebounced = debounce(onInformationInputChange, 250);

	return (
		<Box component="form" noValidate autoComplete="off">
			<Grid container spacing={0} rowSpacing={2} direction="column">
				<Grid item xs={12}>
					<TextField
						required
						fullWidth
						label={translate(intl, 'components.RoadbookMetaData.inputs.roadbookTitle.label')}
						placeholder={translate(intl, 'components.RoadbookMetaData.inputs.roadbookTitle.placeholder')}
						onChange={onTitleInputChangeDebounced}
						onBlur={onTitleInputChangeDebounced}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						multiline
						rows={5}
						fullWidth
						label={translate(intl, 'components.RoadbookMetaData.inputs.roadbookStageInfo.label')}
						placeholder={translate(
							intl,
							'components.RoadbookMetaData.inputs.roadbookStageInfo.placeholder'
						)}
						onChange={onInformationInputChangeDebounced}
						onBlur={onInformationInputChangeDebounced}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default RoadbookMetaData;
