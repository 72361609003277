import { IReduxActionProps } from '@@redux/utils';
import { COOKIE_NAMES, ROADBOOK_SIDE_BAR_SETTINGS } from '@@utils/constants';
import { Cmd, loop } from 'redux-loop';
import { APP_ACTIONS } from '@@redux/actions/app';
import { getCookie, setCookie } from '@@utils/functions/cookie';
import { Status } from '@googlemaps/react-wrapper';

export interface AppStateProps {
	roadbookSidebarOpen: boolean;
	roadbookStageAccordionOpen: boolean;
	roadbookWaypointAccordionOpen: boolean;
	roadbookMetaDataAccordionOpen: boolean;
	map: {
		status: Status | null;
	};
	appbarHeight: number;
	roadbookSidebarWidth: number;
}

const cookieSidebarWidth = getCookie(COOKIE_NAMES.ROADBOOK_SIDEBAR_WIDTH);

const InitialAppState: AppStateProps = {
	roadbookSidebarOpen: true,
	map: {
		status: null,
	},
	appbarHeight: 0,
	roadbookSidebarWidth: cookieSidebarWidth
		? parseInt(cookieSidebarWidth, 10)
		: ROADBOOK_SIDE_BAR_SETTINGS.DEFAULT_WIDTH,
	roadbookStageAccordionOpen: true,
	roadbookWaypointAccordionOpen: true,
	roadbookMetaDataAccordionOpen: false,
};

const App = (state: AppStateProps = InitialAppState, action: IReduxActionProps) => {
	switch (action.type) {
		case APP_ACTIONS.SET_GOOGLE_MAPS_STATUS:
			return {
				...state,
				map: {
					...state.map,
					status: action.payload?.status,
				},
			};
		case APP_ACTIONS.SET_ROADBOOK_SIDEBAR_WIDTH_COOKIE:
			setCookie(COOKIE_NAMES.ROADBOOK_SIDEBAR_WIDTH, state.roadbookSidebarWidth.toFixed(), 365);
			return state;
		case APP_ACTIONS.TOGGLE_ROADBOOK_SIDEBAR_ROADBOOK_METADATA_ACCORDION_OPEN:
			return {
				...state,
				roadbookMetaDataAccordionOpen:
					action.payload?.open !== undefined ? action.payload?.open : !state.roadbookMetaDataAccordionOpen,
			};
		case APP_ACTIONS.TOGGLE_ROADBOOK_SIDEBAR_STAGE_ACCORDION_OPEN:
			return {
				...state,
				roadbookStageAccordionOpen:
					action.payload?.open !== undefined ? action.payload?.open : !state.roadbookStageAccordionOpen,
			};
		case APP_ACTIONS.TOGGLE_ROADBOOK_SIDEBAR_WAYPOINT_ACCORDION_OPEN:
			return {
				...state,
				roadbookWaypointAccordionOpen:
					action.payload?.open !== undefined ? action.payload?.open : !state.roadbookWaypointAccordionOpen,
			};
		case APP_ACTIONS.SET_APPBAR_HEIGHT:
			return {
				...state,
				appbarHeight: action.payload?.height,
			};
		case APP_ACTIONS.SET_ROADBOOK_SIDEBAR_WIDTH:
			return loop(
				{
					...state,
					roadbookSidebarWidth: action.payload?.width,
				},
				Cmd.list([
					Cmd.action({
						type: APP_ACTIONS.SET_ROADBOOK_SIDEBAR_WIDTH_COOKIE,
					}),
				])
			);
		case APP_ACTIONS.TOGGLE_ROADBOOK_SIDEBAR:
			return {
				...state,
				roadbookSidebarOpen: !state.roadbookSidebarOpen,
			};
		case APP_ACTIONS.OPEN_ROADBOOK_SIDEBAR:
			return {
				...state,
				roadbookSidebarOpen: true,
			};
		case APP_ACTIONS.CLOSE_ROADBOOK_SIDEBAR:
			return {
				...state,
				roadbookSidebarOpen: false,
			};
		default:
			return state;
	}
};

export default App;
