import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import { install } from 'redux-loop';
import { Provider as ReduxProvider } from 'react-redux';
import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import AppStore from '@@redux/index';
import { ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';
import { RoadbookBuddyTheme } from '@@config/material-ui/theme';
import RoadbookSidebar from '@@components/AppShell/RoadbookSidebar';
import { CssBaseline } from '@mui/material';
import AppBar from '@@components/AppShell/AppBar';
import LanguageContextWrapper from '@@components/Global/LanguageContextWrapper';
import PageTitleSwitcher from '@@components/Global/PageTitleSwitcher';
import MapWrapper from '@@components/Map/Wrapper';

const store = createStore(AppStore as any, composeWithDevTools(install()));

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container as Element);

root.render(
	<ReduxProvider store={store}>
		<LanguageContextWrapper>
			<MaterialThemeProvider theme={RoadbookBuddyTheme}>
				<CssBaseline />
				<AppBar />
				<RoadbookSidebar />
				<MapWrapper />
				<PageTitleSwitcher />
			</MaterialThemeProvider>
		</LanguageContextWrapper>
	</ReduxProvider>
);
