import { addWaypointAfterIndex, setHoveredWaypointUuid } from '@@redux/actions/roadbook';
import { StageProps, WaypointProps } from '@@redux/reducers/roadbook';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { contrastColor } from 'contrast-color';

export interface PolylineWrapperProps {
	start: google.maps.LatLng;
	end: google.maps.LatLng;
	waypointIndexStart: number;
	waypointUuidStart: WaypointProps['uuid'];
	waypointIndexEnd: number;
	waypointUuidEnd: WaypointProps['uuid'];
	color: StageProps['color'];
	polylineProps?: google.maps.PolylineOptions;
	stageUuid: StageProps['uuid'];
}

const PolylineWrapper: FC<PolylineWrapperProps> = ({
	start,
	end,
	color,
	polylineProps,
	waypointIndexStart,
	waypointUuidStart,
	waypointIndexEnd,
	waypointUuidEnd,
	stageUuid,
}: PolylineWrapperProps): JSX.Element | null => {
	const dispatch = useDispatch();

	const [ path, setPath ] = useState<google.maps.Polyline>();
	const [ isHovered, setIsHovered ] = useState<boolean>(false);

	const getCustomPathProps = useCallback((): google.maps.PolylineOptions => {
		const polylineContrastColorColor = contrastColor({
			bgColor: color,
		});

		return {
			...polylineProps,
			path: [ start, end ],
			geodesic: true,
			strokeColor: !isHovered ? color : polylineContrastColorColor,
			strokeOpacity: 0.5,
			strokeWeight: 5,
			clickable: true,
			draggable: false,
			zIndex: 1,
			icons: [
				{
					icon: {
						path: 'M7 14l5-5 5 5H7z',
						fillColor: !isHovered ? color : polylineContrastColorColor,
						fillOpacity: 0.5,
						scale: 1.25,
						anchor: new google.maps.Point(12, 12),
					},
					offset: '50%',
				},
			],
		};
	}, [ start, end, color, waypointIndexStart, waypointIndexEnd, isHovered ]);

	const removeAllListeners = () => {
		if (!path) return;
		google.maps.event.clearInstanceListeners(path);
	};

	const onClick = (event: google.maps.PolyMouseEvent) => {
		if (!path || !event.latLng) return;

		dispatch(
			addWaypointAfterIndex({
				stageUuid,
				lat: event.latLng.lat(),
				lng: event.latLng.lng(),
				index: waypointIndexEnd,
			})
		);
	};

	const onMouseOver = () => {
		setIsHovered(true);
		dispatch(
			setHoveredWaypointUuid({
				uuid: [ waypointUuidStart, waypointUuidEnd ],
			})
		);
	};

	const onMouseOut = () => {
		setIsHovered(false);
		dispatch(
			setHoveredWaypointUuid({
				uuid: null,
			})
		);
	};

	useEffect(() => {
		if (!path) {
			setPath(new google.maps.Polyline());
		}

		return () => {
			if (path) {
				removeAllListeners();
				path.setMap(null);
			}
		};
	}, [ path ]);

	useEffect(() => {
		if (path) {
			path.setOptions(getCustomPathProps());
			removeAllListeners();
			path.addListener('click', onClick);
			path.addListener('mouseover', onMouseOver);
			path.addListener('mouseout', onMouseOut);
		}
	}, [ path, color, start, end, waypointIndexStart, waypointIndexEnd, isHovered ]);

	return null;
};

export default PolylineWrapper;
