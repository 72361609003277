import { AppStoreProps } from '@@redux/index';
import { setGoogleMapsStatus } from '@@redux/actions/app';
import { WAYPOINT_SIZES, WAYPOINT_TYPE } from '@@utils/constants';
import { contrastColor } from 'contrast-color';
import { Status } from '@googlemaps/react-wrapper';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/material';
import { addWaypoint } from '@@redux/actions/roadbook';
import { StageProps } from '@@redux/reducers/roadbook';
import StageWrapper from './StageWrapper';
import { createSVG } from '@@utils/functions/createSvg';

const Map = (): JSX.Element => {
	const dispatch = useDispatch();
	const theme = useTheme();
	const userLocation = useSelector((state: AppStoreProps) => state.user.location);
	const selectedStageUuid = useSelector((state: AppStoreProps) => state.roadbook.selectedStageUuid);
	const stages = useSelector((state: AppStoreProps) => state.roadbook.stages);

	const mapRef = useRef<HTMLDivElement>(null);
	const [ map, setMap ] = useState<google.maps.Map | null>(null);

	/**
	 * INIT THE MAP
	 */
	useEffect(() => {
		if (mapRef.current && !map) {
			setMap(
				new window.google.maps.Map(mapRef.current, {
					zoom: 14,
					center: {
						lat: 51.1657,
						lng: 10.4515,
					},
					fullscreenControl: false,
					mapTypeId: 'satellite',
					disableDoubleClickZoom: true,
					draggableCursor: 'default',
				})
			);
		} else if (map) {
			dispatch(setGoogleMapsStatus({ status: Status.SUCCESS }));
		}
	}, [ mapRef, map ]);

	/**
	 * SET USER LOCATION ON MAP
	 */
	useEffect(() => {
		if (map && userLocation !== undefined && userLocation.lat && userLocation.lng) {
			const centerLatLng: google.maps.LatLng = new window.google.maps.LatLng(userLocation.lat, userLocation.lng);
			map.setCenter(centerLatLng);
			map.setZoom(15);

			const iconSize = {
				width: WAYPOINT_SIZES[ WAYPOINT_TYPE.HOME ].width,
				height: WAYPOINT_SIZES[ WAYPOINT_TYPE.HOME ].height,
			};

			new google.maps.Marker({
				position: centerLatLng,
				map,
				icon: {
					url: createSVG({
						icon: WAYPOINT_TYPE.HOME,
						...iconSize,
						toUrlString: true,
						withSvgHeader: true,
						backgroundColor: theme.palette.primary.main,
						foregroundColor: contrastColor({
							bgColor: theme.palette.primary.main,
						}),
					}),
				},
			});
		}
	}, [ userLocation, map ]);

	/**
	 * MAP EVENT LISTENERS
	 */
	const removeAllMapEventListeners = () => {
		if (!map) return;

		const eventNames: string[] = [ 'click' ];
		eventNames.forEach((eventName: string) => google.maps.event.clearListeners(map, eventName));
	};

	const onMapClick = useCallback(
		(event: google.maps.MapMouseEvent) => {
			if (typeof selectedStageUuid === 'string') {
				dispatch(
					addWaypoint({
						lat: event.latLng?.lat(),
						lng: event.latLng?.lng(),
						type: WAYPOINT_TYPE.SHAPING_POINT,
						stageUuid: selectedStageUuid,
					})
				);
			}
		},
		[ map, selectedStageUuid ]
	);

	/**
	 * ADD MAP EVENT LISTENERS
	 */
	useEffect(() => {
		if (map) {
			removeAllMapEventListeners();

			map.addListener('click', onMapClick);
		}
	}, [ map, selectedStageUuid ]);

	return (
		<Box component="div" sx={{ height: '100%', width: '100%' }} ref={mapRef}>
			{map &&
				stages.map((stage: StageProps) => {
					return <StageWrapper key={`StageWrapper-${stage.uuid}`} {...stage} map={map} />;
				})}
		</Box>
	);
};

export default Map;
