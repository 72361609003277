import { useCallback, useEffect, useState } from 'react';

type UseResizeProps = {
	minWidth: number;
	defaultWidth: number;
};

type UseResizeReturn = {
	width: number;
	defaultWidth: number;
	enableResize: () => void;
};

const useResize = ({ minWidth, defaultWidth }: UseResizeProps): UseResizeReturn => {
	const [ isResizing, setIsResizing ] = useState(false);
	const [ width, setWidth ] = useState(defaultWidth);

	const enableResize = useCallback(() => {
		setIsResizing(true);
	}, [ setIsResizing ]);

	const disableResize = useCallback(() => {
		setIsResizing(false);
	}, [ setIsResizing ]);

	const resize = useCallback(
		(e: MouseEvent) => {
			if (isResizing) {
				const newWidth = window.innerWidth - e.clientX;
				if (newWidth >= minWidth) {
					setWidth(newWidth);
				}
			}
		},
		[ minWidth, isResizing, setWidth, defaultWidth ]
	);

	useEffect(() => {
		document.addEventListener('mousemove', resize);
		document.addEventListener('mouseup', disableResize);

		return () => {
			document.removeEventListener('mousemove', resize);
			document.removeEventListener('mouseup', disableResize);
		};
	}, [ disableResize, resize ]);

	return { width, enableResize, defaultWidth };
};

export default useResize;
