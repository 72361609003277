export function setCookie(name: string, val: string, days?: number) {
	let cookieString = name + '=' + val + ';';

	if (days) {
		const date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		cookieString += ' expires=' + date.toUTCString() + '; path=/';
	}

	document.cookie = cookieString;
}

export function getCookie(name: string): string | undefined {
	const value = '; ' + document.cookie;
	const parts = value.split('; ' + name + '=');

	if (parts.length == 2) {
		return parts.pop()?.split(';').shift();
	}

	return undefined;
}

export function deleteCookie(name: string) {
	const date = new Date();
	date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);
	document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/';
}
