import { translate } from '@@i18n/utils';
import { AppStoreProps } from '@@redux/index';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const PageTitleSwitcher = (): JSX.Element | null => {
	const intl = useIntl();
	const userLocale = useSelector((state: AppStoreProps) => state.user.locale);

	useEffect(() => {
		document.title = translate(intl, 'generic.app.title');
	}, [ userLocale ]);

	return null;
};

export default PageTitleSwitcher;
