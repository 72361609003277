import { LatLngProps } from './../../../../utils/constants/index';
import { StageProps, WaypointProps } from '@@redux/reducers/roadbook';
import { WAYPOINT_TYPE, DRAG_STATES } from '@@utils/constants';
import { IReduxActionProps } from '@@redux/utils';

export enum ROADBOOK_ACTIONS {
	SET_ROADBOOK_TITLE = 'SET_ROADBOOK_TITLE',
	SET_ROADBOOK_INFORMATION = 'SET_ROADBOOK_INFORMATION',
	ADD_WAYPOINT = 'ADD_WAYPOINT',
	CONVERT_WAYPOINT = 'CONVERT_WAYPOINT',
	DELETE_WAYPOINT = 'DELETE_WAYPOINT',
	MOVE_WAYPOINT = 'MOVE_WAYPOINT',
	SET_WAYPOINT_HIGHLIGHTED = 'SET_WAYPOINT_HIGHLIGHTED',
	SET_HOVERED_WAYPOINT_UUID = 'SET_HOVERED_WAYPOINT_UUID',
	SET_DRAGGING_WAYPOINT_UUID = 'SET_DRAGGING_WAYPOINT_UUID',
	TOGGLE_STAGE_ACTIVE = 'TOGGLE_STAGE_ACTIVE',
	TOGGLE_STAGE_VISIBLE_ON_MAP = 'TOGGLE_STAGE_VISIBLE_ON_MAP',
	SET_STAGE_TITLE = 'SET_STAGE_TITLE',
	SET_STAGE_COLOR = 'SET_STAGE_COLOR',
	ADD_STAGE = 'ADD_STAGE',
	DELETE_STAGE = 'DELETE_STAGE',
	TOGGLE_SELECTED_STAGE_UUID = 'TOGGLE_SELECTED_STAGE_UUID',
	UPDATE_ACTIVE_STAGE_COUNT = 'UPDATE_ACTIVE_STAGE_COUNT',
	SORT_STAGES_DRAG_N_DROP = 'SORT_STAGES_DRAG_N_DROP',
	UPDATE_STAGE_SORTING = 'UPDATE_STAGE_SORTING',
	SET_LAST_STAGE_AS_ACTIVE = 'SET_LAST_STAGE_AS_ACTIVE',
	UPDATE_WAYPOINT_ORDER = 'UPDATE_WAYPOINT_ORDER',
	UPDATE_WAYPOINT_ORDER_FOR_STAGE_BY_INDEX = 'UPDATE_WAYPOINT_ORDER_FOR_STAGE_BY_INDEX',
	SORT_WAYPOINTS_FOR_STAGE_DRAG_N_DROP = 'SORT_WAYPOINTS_FOR_STAGE_DRAG_N_DROP',
	SET_WAYPOINT_DRAG_STATE = 'SET_WAYPOINT_DRAG_STATE',
	ADD_WAYPOINT_AFTER_INDEX = 'ADD_WAYPOINT_AFTER_INDEX',
	SET_LAST_WAYPOINT_AS_START_FINISH_TYPE = 'SET_LAST_WAYPOINT_AS_START_FINISH_TYPE',
	CALCULATE_DISTANCES = 'CALCULATE_DISTANCES',
}

export const setRoadbookTitle = ({ title }: { title: string }): IReduxActionProps => {
	return {
		type: ROADBOOK_ACTIONS.SET_ROADBOOK_TITLE,
		payload: {
			title,
		},
	};
};

export const setRoadbookInformation = ({ information }: { information: string }): IReduxActionProps => {
	return {
		type: ROADBOOK_ACTIONS.SET_ROADBOOK_INFORMATION,
		payload: {
			information,
		},
	};
};

export const addWaypoint = ({
	lat,
	lng,
	type,
	stageUuid,
}: {
	lat: LatLngProps['lat'] | undefined;
	lng: LatLngProps['lng'] | undefined;
	type: WAYPOINT_TYPE;
	stageUuid: StageProps['uuid'];
}): IReduxActionProps | void => {
	if (lat && lng) {
		return {
			type: ROADBOOK_ACTIONS.ADD_WAYPOINT,
			payload: {
				lat,
				lng,
				type,
				stageUuid,
			},
		};
	}
};

export const convertWaypoint = ({
	uuid,
	type,
	stageUuid,
}: {
	uuid: WaypointProps['uuid'];
	type: WAYPOINT_TYPE;
	stageUuid: StageProps['uuid'];
}): IReduxActionProps => {
	return {
		type: ROADBOOK_ACTIONS.CONVERT_WAYPOINT,
		payload: {
			uuid,
			type,
			stageUuid,
		},
	};
};

export const deleteWaypoint = ({
	uuid,
	stageUuid,
}: {
	uuid: WaypointProps['uuid'];
	stageUuid: StageProps['uuid'];
}): IReduxActionProps => {
	return {
		type: ROADBOOK_ACTIONS.DELETE_WAYPOINT,
		payload: {
			uuid,
			stageUuid,
		},
	};
};

export const moveWaypoint = ({
	uuid,
	lat,
	lng,
	stageUuid,
}: {
	uuid: WaypointProps['uuid'];
	lat: LatLngProps['lat'] | undefined;
	lng: LatLngProps['lng'] | undefined;
	stageUuid: StageProps['uuid'];
}): IReduxActionProps => {
	return {
		type: ROADBOOK_ACTIONS.MOVE_WAYPOINT,
		payload: {
			uuid,
			lat,
			lng,
			stageUuid,
		},
	};
};

export const setWaypointHighlighted = ({
	uuid,
	highlighted,
	stageUuid,
}: {
	uuid: WaypointProps['uuid'];
	highlighted: boolean;
	stageUuid: StageProps['uuid'];
}): IReduxActionProps => {
	return {
		type: ROADBOOK_ACTIONS.SET_WAYPOINT_HIGHLIGHTED,
		payload: {
			uuid,
			highlighted,
			stageUuid,
		},
	};
};

export const setHoveredWaypointUuid = ({
	uuid,
}: {
	uuid: WaypointProps['uuid'] | WaypointProps['uuid'][] | null;
}): IReduxActionProps => {
	return {
		type: ROADBOOK_ACTIONS.SET_HOVERED_WAYPOINT_UUID,
		payload: {
			uuid,
		},
	};
};

export const setDraggingWaypointUuid = ({
	uuid,
}: {
	uuid: WaypointProps['uuid'] | WaypointProps['uuid'][] | null;
}): IReduxActionProps => {
	return {
		type: ROADBOOK_ACTIONS.SET_DRAGGING_WAYPOINT_UUID,
		payload: {
			uuid,
		},
	};
};

export const toggleStageActive = ({
	uuid,
	active,
}: {
	uuid: StageProps['uuid'];
	active?: boolean;
}): IReduxActionProps => {
	return {
		type: ROADBOOK_ACTIONS.TOGGLE_STAGE_ACTIVE,
		payload: {
			uuid,
			active,
		},
	};
};

export const toggleStageVisibleOnMap = ({
	uuid,
	visible,
}: {
	uuid: StageProps['uuid'];
	visible?: boolean;
}): IReduxActionProps => {
	return {
		type: ROADBOOK_ACTIONS.TOGGLE_STAGE_VISIBLE_ON_MAP,
		payload: {
			uuid,
			visible,
		},
	};
};

export const setStageTitle = ({ uuid, title }: { uuid: StageProps['uuid']; title: string }): IReduxActionProps => {
	return {
		type: ROADBOOK_ACTIONS.SET_STAGE_TITLE,
		payload: {
			uuid,
			title,
		},
	};
};

export const setStageColor = ({ uuid, color }: { uuid: StageProps['uuid']; color: string }): IReduxActionProps => {
	return {
		type: ROADBOOK_ACTIONS.SET_STAGE_COLOR,
		payload: {
			uuid,
			color,
		},
	};
};

export const addStage = ({ title }: { title: string }): IReduxActionProps => {
	return {
		type: ROADBOOK_ACTIONS.ADD_STAGE,
		payload: {
			title,
		},
	};
};

export const deleteStage = ({ uuid }: { uuid: StageProps['uuid'] }): IReduxActionProps => {
	return {
		type: ROADBOOK_ACTIONS.DELETE_STAGE,
		payload: {
			uuid,
		},
	};
};

export const setSelectedStageUuid = ({ uuid }: { uuid: StageProps['uuid'] }): IReduxActionProps => {
	return {
		type: ROADBOOK_ACTIONS.TOGGLE_SELECTED_STAGE_UUID,
		payload: {
			uuid,
		},
	};
};

export const sortStagesDragNDrop = ({ uuids }: { uuids: StageProps['uuid'][] }): IReduxActionProps => {
	return {
		type: ROADBOOK_ACTIONS.SORT_STAGES_DRAG_N_DROP,
		payload: {
			uuids,
		},
	};
};

export const sortWaypointsForStageDragNDrop = ({
	uuids,
	stageUuid,
}: {
	uuids: WaypointProps['uuid'][];
	stageUuid: StageProps['uuid'];
}): IReduxActionProps => {
	return {
		type: ROADBOOK_ACTIONS.SORT_WAYPOINTS_FOR_STAGE_DRAG_N_DROP,
		payload: {
			uuids,
			stageUuid,
		},
	};
};

export const setWaypointDragState = ({
	stageUuid,
	uuid,
	dragState,
	lat,
	lng,
}: {
	stageUuid: StageProps['uuid'];
	uuid: WaypointProps['uuid'];
	dragState: DRAG_STATES;
	lat?: number;
	lng?: number;
}): IReduxActionProps => {
	return {
		type: ROADBOOK_ACTIONS.SET_WAYPOINT_DRAG_STATE,
		payload: {
			stageUuid,
			uuid,
			dragState,
			lat,
			lng,
		},
	};
};

export const addWaypointAfterIndex = ({
	index,
	stageUuid,
	type,
	lat,
	lng,
}: {
	index: number;
	stageUuid: StageProps['uuid'];
	lat: LatLngProps['lat'] | undefined;
	lng: LatLngProps['lng'] | undefined;
	type?: WAYPOINT_TYPE;
}): IReduxActionProps => {
	return {
		type: ROADBOOK_ACTIONS.ADD_WAYPOINT_AFTER_INDEX,
		payload: {
			index,
			stageUuid,
			type: type || WAYPOINT_TYPE.SHAPING_POINT,
			lat,
			lng,
		},
	};
};
