import { createTheme, ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
	palette: {
		mode: 'light',
		primary: {
			main: '#388e3c',
		},
		secondary: {
			main: '#0277bd',
		},
		error: {
			main: '#c62828',
		},
		info: {
			main: '#fdd835',
		},
		warning: {
			main: '#fb8c00',
		},
	},
	shape: {
		borderRadius: 0,
	}
};

export const RoadbookBuddyTheme = createTheme(themeOptions);
