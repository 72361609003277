import { kilometersToMiles } from './kilometersToMiles';
import { DISTANCE_UNIT } from './../constants/index';
import { IntlShape } from 'react-intl';

export interface FormatDistanceProps {
	intl: IntlShape;
	distance: number | null | undefined;
	distanceUnit?: DISTANCE_UNIT;
	hideUnit?: boolean;
}

export const formatDistance = ({ intl, distance, distanceUnit, hideUnit }: FormatDistanceProps): string => {
	return intl.formatNumber(distanceUnit === DISTANCE_UNIT.MILES ? kilometersToMiles(distance || 0) : distance || 0, {
		unit: distanceUnit === DISTANCE_UNIT.KILOMETERS ? 'kilometer' : 'mile',
		maximumFractionDigits: 3,
		minimumFractionDigits: 3,
		unitDisplay: 'short',
		style: hideUnit ? 'decimal' : 'unit',
	});
};
