import { toRadians } from './toRadians';
import { WaypointProps } from '@@redux/reducers/roadbook';

export const waypointAngle = (waypoint1: WaypointProps, waypoint2: WaypointProps) => {
	const y = Math.sin(toRadians(waypoint2.lng - waypoint1.lng)) * Math.cos(toRadians(waypoint2.lat));
	const x =
		Math.cos(toRadians(waypoint1.lat)) * Math.sin(toRadians(waypoint2.lat)) -
		Math.sin(toRadians(waypoint1.lat)) *
			Math.cos(toRadians(waypoint2.lat)) *
			Math.cos(toRadians(waypoint2.lng - waypoint1.lng));
	const bearing = (Math.atan2(y, x) * 180) / Math.PI;

	return bearing;
};
