import { toDegrees } from './toDegrees';
import { WaypointProps } from '@@redux/reducers/roadbook';
import { toRadians } from './toRadians';

/**
 * https://gis.stackexchange.com/questions/108171/calculating-azimuth-from-two-points-both-having-latitude-longtiude
 */
export const bearingAngle = (waypoint1: WaypointProps, waypoint2: WaypointProps) => {
	const lat1 = toRadians(waypoint1.lat);
	const lat2 = toRadians(waypoint2.lat);

	const lngDelta = toRadians(waypoint2.lng - waypoint1.lng);
	const y = Math.sin(lngDelta) * Math.cos(lat2);
	const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lngDelta);
	const result = toDegrees(Math.atan2(y, x));

	return (result + 360) % 360;
};
