import { IReduxActionProps } from '@@redux/utils';
import { DISTANCE_UNIT, SUPPORTED_LOCALES } from '@@utils/constants';

export enum USER_ACTIONS {
	SET_LOCALE = 'SET_LOCALE',
	SET_LOCATION = 'SET_LOCATION',
	SET_LOCATION_ERROR = 'SET_LOCATION_ERROR',
	SET_LOCATION_FETCHING = 'SET_LOCATION_FETCHING',
	SET_DISTANCE_UNIT = 'SET_DISTANCE_UNIT',
}

export const setLocale = ({ locale }: { locale: SUPPORTED_LOCALES }): IReduxActionProps => {
	return {
		type: USER_ACTIONS.SET_LOCALE,
		payload: {
			locale,
		},
	};
};

export const setLocation = ({ lat, lng }: { lat: number; lng: number }): IReduxActionProps => {
	return {
		type: USER_ACTIONS.SET_LOCATION,
		payload: {
			lat,
			lng,
		},
	};
};

export const setLocationError = ({ error }: { error: boolean }): IReduxActionProps => {
	return {
		type: USER_ACTIONS.SET_LOCATION_ERROR,
		payload: {
			error,
		},
	};
};

export const setLocationFetching = ({ fetching }: { fetching: boolean }): IReduxActionProps => {
	return {
		type: USER_ACTIONS.SET_LOCATION_FETCHING,
		payload: {
			fetching,
		},
	};
};

export const setDistanceUnit = ({ unit }: { unit: DISTANCE_UNIT }): IReduxActionProps => {
	return {
		type: USER_ACTIONS.SET_DISTANCE_UNIT,
		payload: {
			unit,
		},
	};
};
