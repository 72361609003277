import { StageProps } from '@@redux/reducers/roadbook';
import IconButton from '@mui/material/IconButton';
import { Button, ButtonGroup, Popover } from '@mui/material';
import { translate } from '@@i18n/utils';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { deleteStage } from '@@redux/actions/roadbook';

export interface DeleteIconButtonProps {
	stage: StageProps;
}
const DeleteIconButton = ({ stage }: DeleteIconButtonProps) => {
	const intl = useIntl();

	const dispatch = useDispatch();

	const [ popOverAnchorEl, setPopOverAnchorEl ] = useState<HTMLButtonElement | null>(null);

	return (
		<>
			<Popover
				id={`delete-popover-${stage.uuid}`}
				open={Boolean(popOverAnchorEl)}
				anchorEl={popOverAnchorEl}
				onClose={() => setPopOverAnchorEl(null)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<ButtonGroup variant="contained">
					<Button
						color="error"
						onClick={() => {
							dispatch(
								deleteStage({
									uuid: stage.uuid,
								})
							);
						}}
					>
						{translate(intl, 'components.StageDataGrid.stageItem.deleteButton.confirmationYes')}
					</Button>
					<Button color="success" onClick={() => setPopOverAnchorEl(null)}>
						{translate(intl, 'components.StageDataGrid.stageItem.deleteButton.confirmationNo')}
					</Button>
				</ButtonGroup>
			</Popover>
			<IconButton
				disabled={!stage.active}
				onClick={(event: React.MouseEvent<HTMLButtonElement>) => setPopOverAnchorEl(event.currentTarget)}
			>
				<DeleteForeverIcon />
			</IconButton>
		</>
	);
};

export default DeleteIconButton;
