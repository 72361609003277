import { waypointAngle } from '@@utils/functions/waypointAngle';
import { WAYPOINT_TYPE, DRAG_STATES, LatLngProps, UUID } from '@@utils/constants/index';
import { ROADBOOK_ACTIONS } from '@@redux/actions/roadbook/index';
import { IReduxActionProps } from '@@redux/utils';
import { v4 as uuidv4 } from 'uuid';
import { Cmd, loop } from 'redux-loop';
import { sortByOrderProperty } from '@@utils/functions/sortByOrderProperty';
import { sumDistances } from '@@utils/functions/sumDistances';
import { bearingAngle } from '@@utils/functions/bearingAngle';

export interface StageProps {
	uuid: UUID;
	title?: string | null;
	information?: string | null;
	waypoints?: WaypointProps[];
	color?: string;
	active?: boolean;
	visibleOnMap?: boolean;
	order?: number;
	distance?: number;
}

export interface WaypointProps {
	uuid: UUID;
	lat: LatLngProps['lat'];
	lng: LatLngProps['lng'];
	type: WAYPOINT_TYPE;
	highlighted?: boolean;
	stageUuid: StageProps['uuid'];
	order?: number;
	dragState?: DRAG_STATES;
	typeChangedByUser?: boolean;
	distanceToLast?: number;
	distanceToLastWaypoint?: number;
	distanceToStart?: number;
	bearingAngle?: number;
	waypointAngle?: number;
}

export interface RoadbookProps {
	stages: StageProps[];
	title: string | null;
	information?: string | null;
	hoveredWaypointUuid?: WaypointProps['uuid'];
	draggingWaypointUuid?: WaypointProps['uuid'];
	selectedStageUuid?: StageProps['uuid'];
	activeStages: number;
}

const InitialRoadbookState: RoadbookProps = {
	stages: [],
	title: null,
	information: null,
	activeStages: 0,
};

const RoadbookState = (state: RoadbookProps = InitialRoadbookState, action: IReduxActionProps) => {
	switch (action.type) {
		case ROADBOOK_ACTIONS.CALCULATE_DISTANCES:
			return {
				...state,
				stages: state.stages.map((stage: StageProps) => {
					if (stage.uuid === action.payload?.stageUuid) {
						stage.distance = 0;

						if (Array.isArray(stage.waypoints)) {
							const waypointCount = stage.waypoints.length;

							for (let waypointIndex = 0; waypointIndex < waypointCount; waypointIndex++) {
								const waypoint = stage.waypoints[ waypointIndex ];
								waypoint.distanceToStart = 0;
								waypoint.distanceToLast = 0;
								waypoint.distanceToLastWaypoint = 0;

								if (waypointIndex > 0) {
									waypoint.distanceToStart = sumDistances({
										waypoints: stage.waypoints || [],
										untilWaypointType: WAYPOINT_TYPE.START,
										startIndex: waypointIndex,
									});
									waypoint.distanceToLastWaypoint = sumDistances({
										waypoints: stage.waypoints || [],
										untilWaypointType: WAYPOINT_TYPE.WAYPOINT || WAYPOINT_TYPE.START,
										startIndex: waypointIndex,
									});
									waypoint.distanceToLast = sumDistances({
										waypoints: stage.waypoints || [],
										untilWaypointIndex: waypointIndex - 1,
										startIndex: waypointIndex,
									});
								}

								if (waypointIndex < waypointCount - 1) {
									waypoint.bearingAngle = bearingAngle(waypoint, stage.waypoints[ waypointIndex + 1 ]);
									waypoint.waypointAngle = waypointAngle(
										waypoint,
										stage.waypoints[ waypointIndex + 1 ]
									);
								} else {
									waypoint.bearingAngle = undefined;
									waypoint.waypointAngle = undefined;
								}
							}

							stage.distance = stage.waypoints[ waypointCount - 1 ].distanceToStart;
						}
					}

					return stage;
				}),
			};
		case ROADBOOK_ACTIONS.SET_LAST_WAYPOINT_AS_START_FINISH_TYPE:
			return {
				...state,
				stages: state.stages.map((stage: StageProps) => {
					if (stage.uuid === action.payload?.stageUuid) {
						const waypointCount = (stage.waypoints?.length || 0) - 1;

						stage.waypoints?.map((waypoint: WaypointProps, waypointIndex: number) => {
							if (waypointIndex === 0) waypoint.type = WAYPOINT_TYPE.START;

							if (waypointIndex > 0 && waypointIndex === waypointCount) {
								waypoint.type = WAYPOINT_TYPE.FINISH;
							}

							if (waypoint.type === WAYPOINT_TYPE.START && waypointIndex !== 0) {
								waypoint.type = WAYPOINT_TYPE.SHAPING_POINT;
							}

							if (waypoint.type === WAYPOINT_TYPE.FINISH && waypointIndex !== waypointCount) {
								waypoint.type = WAYPOINT_TYPE.FINISH;
							}

							if (waypointIndex !== 0 && waypointIndex !== waypointCount && !waypoint.typeChangedByUser) {
								waypoint.type = WAYPOINT_TYPE.SHAPING_POINT;
							}

							if (
								(waypointIndex !== waypointCount && waypoint.type === WAYPOINT_TYPE.FINISH) ||
								(waypointIndex !== 0 && waypoint.type === WAYPOINT_TYPE.START)
							) {
								waypoint.type = WAYPOINT_TYPE.SHAPING_POINT;
							}

							return waypoint;
						});
					}
					return stage;
				}),
			};
		case ROADBOOK_ACTIONS.ADD_WAYPOINT_AFTER_INDEX:
			return loop(
				{
					...state,
					stages: state.stages.map((stage: StageProps) => {
						if (stage.uuid === action.payload?.stageUuid && stage.waypoints && stage.waypoints.length > 0) {
							stage.waypoints.splice(action.payload?.index, 0, {
								uuid: uuidv4(),
								lat: action.payload?.lat,
								lng: action.payload?.lng,
								type: action.payload?.type || WAYPOINT_TYPE.SHAPING_POINT,
								stageUuid: action.payload?.stageUuid,
								order: action.payload?.index,
								dragState: DRAG_STATES.DEFAULT,
							});
						}
						return stage;
					}),
				} as RoadbookProps,
				Cmd.list([
					Cmd.action({
						type: ROADBOOK_ACTIONS.UPDATE_WAYPOINT_ORDER_FOR_STAGE_BY_INDEX,
						payload: {
							stageUuid: action.payload?.stageUuid,
						},
					}),
					Cmd.action({
						type: ROADBOOK_ACTIONS.SET_LAST_WAYPOINT_AS_START_FINISH_TYPE,
						payload: {
							stageUuid: action.payload?.stageUuid,
						},
					}),
					Cmd.action({
						type: ROADBOOK_ACTIONS.CALCULATE_DISTANCES,
						payload: {
							stageUuid: action.payload?.stageUuid,
						},
					}),
				])
			);
		case ROADBOOK_ACTIONS.UPDATE_WAYPOINT_ORDER_FOR_STAGE_BY_INDEX:
			return loop(
				{
					...state,
					stages: state.stages.map((stage: StageProps) => {
						if (stage.uuid === action.payload?.stageUuid && stage.waypoints && stage.waypoints.length > 0) {
							stage.waypoints = stage.waypoints
								.map((waypoint: WaypointProps, waypointIndex: number) => {
									waypoint.order = waypointIndex;

									return waypoint;
								})
								.sort(sortByOrderProperty);
						}

						return stage;
					}),
				} as RoadbookProps,
				Cmd.list([
					Cmd.action({
						type: ROADBOOK_ACTIONS.SET_LAST_WAYPOINT_AS_START_FINISH_TYPE,
						payload: {
							stageUuid: action.payload?.stageUuid,
						},
					}),
					Cmd.action({
						type: ROADBOOK_ACTIONS.CALCULATE_DISTANCES,
						payload: {
							stageUuid: action.payload?.stageUuid,
						},
					}),
				])
			);
		case ROADBOOK_ACTIONS.SET_WAYPOINT_DRAG_STATE:
			return {
				...state,
				stages: state.stages.map((stage: StageProps) => {
					if (stage.uuid === action.payload?.stageUuid) {
						stage.waypoints = (stage.waypoints || [])?.map((waypoint: WaypointProps) => {
							if (waypoint.uuid === action.payload?.uuid) {
								waypoint.dragState = action.payload?.dragState;
								if (action.payload?.lat) waypoint.lat = action.payload?.lat;
								if (action.payload?.lng) waypoint.lng = action.payload?.lng;
							}
							return waypoint;
						});
					}

					return stage;
				}),
			} as RoadbookProps;
		case ROADBOOK_ACTIONS.SET_LAST_STAGE_AS_ACTIVE:
			return {
				...state,
				selectedStageUuid:
					Array.isArray(state.stages) && state.stages.length > 0
						? state.stages[ state.stages.length - 1 ].uuid
						: null,
			} as RoadbookProps;
		case ROADBOOK_ACTIONS.UPDATE_STAGE_SORTING:
			return {
				...state,
				stages: state.stages.map((stage: StageProps, stageIndex: number) => {
					return {
						...stage,
						order: stageIndex,
					};
				}),
			} as RoadbookProps;
		case ROADBOOK_ACTIONS.SORT_STAGES_DRAG_N_DROP:
			return {
				...state,
				stages: state.stages
					.map((stage: StageProps) => {
						return {
							...stage,
							order: action.payload?.uuids.indexOf(stage.uuid),
						};
					})
					.sort(sortByOrderProperty),
			} as RoadbookProps;
		case ROADBOOK_ACTIONS.SORT_WAYPOINTS_FOR_STAGE_DRAG_N_DROP:
			return loop(
				{
					...state,
					stages: state.stages.map((stage: StageProps) => {
						if (stage.uuid === action.payload?.stageUuid) {
							stage.waypoints = (stage.waypoints || [])
								?.map((waypoint: WaypointProps) => {
									return {
										...waypoint,
										order: action.payload?.uuids.indexOf(waypoint.uuid),
									};
								})
								.sort(sortByOrderProperty);
						}

						return stage;
					}),
				} as RoadbookProps,
				Cmd.list([
					Cmd.action({
						type: ROADBOOK_ACTIONS.SET_LAST_WAYPOINT_AS_START_FINISH_TYPE,
						payload: {
							stageUuid: action.payload?.stageUuid,
						},
					}),
					Cmd.action({
						type: ROADBOOK_ACTIONS.CALCULATE_DISTANCES,
						payload: {
							stageUuid: action.payload?.stageUuid,
						},
					}),
				])
			);
		case ROADBOOK_ACTIONS.TOGGLE_SELECTED_STAGE_UUID:
			return {
				...state,
				selectedStageUuid: state.selectedStageUuid === action.payload?.uuid ? undefined : action.payload?.uuid,
			} as RoadbookProps;
		case ROADBOOK_ACTIONS.ADD_STAGE:
			return loop(
				{
					...state,
					stages: [
						...state.stages,
						{
							uuid: uuidv4(),
							active: true,
							title: action.payload?.title,
							order: state.stages.length,
							visibleOnMap: true,
							distance: 0,
						} as StageProps,
					],
				} as RoadbookProps,
				Cmd.list([
					Cmd.action({
						type: ROADBOOK_ACTIONS.UPDATE_ACTIVE_STAGE_COUNT,
					}),
					Cmd.action({
						type: ROADBOOK_ACTIONS.UPDATE_STAGE_SORTING,
					}),
					Cmd.action({
						type: ROADBOOK_ACTIONS.SET_LAST_STAGE_AS_ACTIVE,
					}),
				])
			);
		case ROADBOOK_ACTIONS.UPDATE_ACTIVE_STAGE_COUNT:
			return {
				...state,
				activeStages: state.stages.filter((stage: StageProps) => stage.active).length,
			} as RoadbookProps;
		case ROADBOOK_ACTIONS.DELETE_STAGE:
			return loop(
				{
					...state,
					stages: state.stages.filter((stage: StageProps) => stage.uuid !== action.payload?.uuid),
					selectedStageUuid:
						state.selectedStageUuid === action.payload?.uuid ? undefined : state.selectedStageUuid,
				} as RoadbookProps,
				Cmd.list([
					Cmd.action({
						type: ROADBOOK_ACTIONS.UPDATE_ACTIVE_STAGE_COUNT,
					}),
					Cmd.action({
						type: ROADBOOK_ACTIONS.UPDATE_STAGE_SORTING,
					}),
					Cmd.action({
						type: ROADBOOK_ACTIONS.SET_LAST_STAGE_AS_ACTIVE,
					}),
				])
			);
		case ROADBOOK_ACTIONS.SET_STAGE_TITLE:
			return {
				...state,
				stages: state.stages.map((stage: StageProps) => {
					if (action.payload?.uuid === stage.uuid) {
						stage.title = action.payload?.title;
					}
					return stage;
				}),
			} as RoadbookProps;
		case ROADBOOK_ACTIONS.SET_STAGE_COLOR:
			return {
				...state,
				stages: state.stages.map((stage: StageProps) => {
					if (action.payload?.uuid === stage.uuid) {
						stage.color = action.payload?.color;
					}
					return stage;
				}),
			} as RoadbookProps;
		case ROADBOOK_ACTIONS.TOGGLE_STAGE_ACTIVE:
			return loop(
				{
					...state,
					stages: state.stages.map((stage: StageProps) => {
						if (action.payload?.uuid === stage.uuid) {
							stage.active =
								action.payload?.active !== undefined ? action.payload?.active : !stage.active;
						}
						return stage;
					}),
					selectedStageUuid:
						state.selectedStageUuid === action.payload?.uuid ? undefined : state.selectedStageUuid,
				} as RoadbookProps,
				Cmd.list([
					Cmd.action({
						type: ROADBOOK_ACTIONS.UPDATE_ACTIVE_STAGE_COUNT,
					}),
					Cmd.action({
						type: ROADBOOK_ACTIONS.UPDATE_STAGE_SORTING,
					}),
				])
			);
		case ROADBOOK_ACTIONS.TOGGLE_STAGE_VISIBLE_ON_MAP:
			return loop(
				{
					...state,
					stages: state.stages.map((stage: StageProps) => {
						if (action.payload?.uuid === stage.uuid) {
							stage.visibleOnMap =
								action.payload?.visible !== undefined ? action.payload?.visible : !stage.visibleOnMap;
						}
						return stage;
					}),
				} as RoadbookProps,
				Cmd.list([ Cmd.none ])
			);
		case ROADBOOK_ACTIONS.SET_ROADBOOK_TITLE:
			return {
				...state,
				title: action.payload?.title,
			} as RoadbookProps;
		case ROADBOOK_ACTIONS.SET_ROADBOOK_INFORMATION:
			return {
				...state,
				information: action.payload?.information,
			} as RoadbookProps;
		case ROADBOOK_ACTIONS.ADD_WAYPOINT:
			return loop(
				{
					...state,
					stages: state.stages.map((stage: StageProps) => {
						if (stage.uuid === action.payload?.stageUuid) {
							stage.waypoints = stage.waypoints || [];
							stage.waypoints.push({
								uuid: uuidv4(),
								lat: action.payload?.lat,
								lng: action.payload?.lng,
								type: action.payload?.type || WAYPOINT_TYPE.SHAPING_POINT,
								stageUuid: action.payload?.stageUuid,
								order: stage.waypoints.length,
								dragState: DRAG_STATES.DEFAULT,
							});
						}
						return stage;
					}),
				} as RoadbookProps,
				Cmd.list([
					Cmd.action({
						type: ROADBOOK_ACTIONS.SET_LAST_WAYPOINT_AS_START_FINISH_TYPE,
						payload: {
							stageUuid: action.payload?.stageUuid,
						},
					}),
					Cmd.action({
						type: ROADBOOK_ACTIONS.CALCULATE_DISTANCES,
						payload: {
							stageUuid: action.payload?.stageUuid,
						},
					}),
				])
			);
		case ROADBOOK_ACTIONS.CONVERT_WAYPOINT:
			return loop(
				{
					...state,
					stages: state.stages.map((stage: StageProps) => {
						if (stage.uuid === action.payload?.stageUuid) {
							stage.waypoints = stage.waypoints?.map((waypoint: WaypointProps) => {
								if (waypoint.uuid === action.payload?.uuid) {
									waypoint.type = action.payload?.type;
									waypoint.typeChangedByUser = true;
								}
								return waypoint;
							});
						}
						return stage;
					}),
				} as RoadbookProps,
				Cmd.list([
					Cmd.action({
						type: ROADBOOK_ACTIONS.SET_LAST_WAYPOINT_AS_START_FINISH_TYPE,
						payload: {
							stageUuid: action.payload?.stageUuid,
						},
					}),
					Cmd.action({
						type: ROADBOOK_ACTIONS.CALCULATE_DISTANCES,
						payload: {
							stageUuid: action.payload?.stageUuid,
						},
					}),
				])
			);
		case ROADBOOK_ACTIONS.DELETE_WAYPOINT:
			return loop(
				{
					...state,
					stages: state.stages.map((stage: StageProps) => {
						if (stage.uuid === action.payload?.stageUuid) {
							stage.waypoints = stage.waypoints?.filter(
								(waypoint: WaypointProps) => waypoint.uuid !== action.payload?.uuid
							);
						}
						return stage;
					}),
				} as RoadbookProps,
				Cmd.list([
					Cmd.action({
						type: ROADBOOK_ACTIONS.SET_LAST_WAYPOINT_AS_START_FINISH_TYPE,
						payload: {
							stageUuid: action.payload?.stageUuid,
						},
					}),
					Cmd.action({
						type: ROADBOOK_ACTIONS.CALCULATE_DISTANCES,
						payload: {
							stageUuid: action.payload?.stageUuid,
						},
					}),
				])
			);
		case ROADBOOK_ACTIONS.MOVE_WAYPOINT:
			return loop(
				{
					...state,
					stages: state.stages.map((stage: StageProps) => {
						if (stage.uuid === action.payload?.stageUuid) {
							stage.waypoints = stage.waypoints?.map((waypoint: WaypointProps) => {
								if (waypoint.uuid === action.payload?.uuid) {
									waypoint.lat = action.payload?.lat;
									waypoint.lng = action.payload?.lng;
								}
								return waypoint;
							});
						}
						return stage;
					}),
				} as RoadbookProps,
				Cmd.list([
					Cmd.action({
						type: ROADBOOK_ACTIONS.CALCULATE_DISTANCES,
						payload: {
							stageUuid: action.payload?.stageUuid,
						},
					}),
				])
			);
		case ROADBOOK_ACTIONS.SET_WAYPOINT_HIGHLIGHTED:
			return {
				...state,
				stages: state.stages.map((stage: StageProps) => {
					if (stage.uuid === action.payload?.stageUuid) {
						stage.waypoints = stage.waypoints?.map((waypoint: WaypointProps) => {
							if (waypoint.uuid === action.payload?.uuid) {
								waypoint.highlighted = action.payload?.highlighted || false;
							}
							return waypoint;
						});
					}
					return stage;
				}),
			} as RoadbookProps;
		case ROADBOOK_ACTIONS.SET_HOVERED_WAYPOINT_UUID:
			return {
				...state,
				hoveredWaypointUuid: action.payload?.uuid,
			} as RoadbookProps;
		case ROADBOOK_ACTIONS.SET_DRAGGING_WAYPOINT_UUID:
			return {
				...state,
				draggingWaypointUuid: action.payload?.uuid,
			} as RoadbookProps;
		default:
			return state;
	}
};

export default RoadbookState;
