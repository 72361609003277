import { IntlShape } from 'react-intl';

/**
 * USAGE
 *
 * import translate from '@allesauto/i18n';
 * import {useIntl} from 'react-intl;
 *
 * function test = (props: any) => {
 *
 * 	const intl = useIntl();
 *
 * 	return <h1>{translate(intl, 'THIS.SHOULD.BE.TRANSLATED')}</h1>
 * }
 */

/**
 * Helper to translate messages via intl
 *
 * @param   {Object}  intl        The intl object, mostly from "injectIntl" HOC of "react-intl"
 * @param   {String}  messageKey  The message key like "components.login.headline"
 * @param   {Object}  params      Params for intl which will be replaced within the message source
 *
 * @return  {String}              The translated message
 */
export const translate = (
	intl: IntlShape,
	messageKey: string,
	params?: {
		[key: string]: string;
	}
): string => {
	return intl.formatMessage(
		{
			id: messageKey,
		},
		params
	);
};

export interface IMessageFileProps {
	[key: string]: {
		[key: string]: string;
	};
}
