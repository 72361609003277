import { StageProps, WaypointProps } from '@@redux/reducers/roadbook';
import { WAYPOINT_SIZES, WAYPOINT_TYPE } from '@@utils/constants';
import { useCallback, useEffect, useState } from 'react';
import { contrastColor } from 'contrast-color';
import { useDispatch, useSelector } from 'react-redux';
import { convertWaypoint, moveWaypoint, setHoveredWaypointUuid } from '@@redux/actions/roadbook';
import { createSVG } from '@@utils/functions/createSvg';
import { AppStoreProps } from '@@redux/index';
import { debounce } from '@@utils/functions/debounce';

export interface MarkerProps extends WaypointProps {
	stageProps: StageProps;
	markerProps?: google.maps.MarkerOptions;
}

const Marker = ({ uuid, type, lat, lng, stageProps, markerProps }: MarkerProps) => {
	const dispatch = useDispatch();

	const [ marker, setMarker ] = useState<google.maps.Marker>();

	const isHovered = useSelector((state: AppStoreProps) =>
		!Array.isArray(state.roadbook.hoveredWaypointUuid)
			? state.roadbook.hoveredWaypointUuid === uuid
			: state.roadbook.hoveredWaypointUuid.includes(uuid));

	const getCustomMarkerProps = (): google.maps.MarkerOptions => {
		const markerConstrastColor = contrastColor({
			bgColor: stageProps.color,
		});

		const iconSize = {
			width: WAYPOINT_SIZES[ type ].width,
			height: WAYPOINT_SIZES[ type ].height,
		};

		const markerIcon = createSVG({
			icon: type,
			toUrlString: true,
			withSvgHeader: true,
			backgroundColor: !isHovered ? stageProps.color : markerConstrastColor,
			foregroundColor: !isHovered ? markerConstrastColor : stageProps.color,
			...iconSize,
		});

		return {
			...markerProps,
			position: new window.google.maps.LatLng(lat, lng),
			draggable: true,
			icon: {
				url: markerIcon,
				anchor: new google.maps.Point(iconSize.width / 2, iconSize.height / 2),
			},
		};
	};

	/**
	 * EVENT LISTENERS
	 */
	const onMarkerClick = useCallback(() => {
		dispatch(
			convertWaypoint({
				uuid,
				type: type === WAYPOINT_TYPE.SHAPING_POINT ? WAYPOINT_TYPE.WAYPOINT : WAYPOINT_TYPE.SHAPING_POINT,
				stageUuid: stageProps.uuid,
			})
		);
	}, [ type, stageProps, uuid ]);

	const onMarkerDragEnd = useCallback(
		(event: google.maps.MapMouseEvent) => {
			addEventListeners();
			// dispatch(setDraggingWaypointUuid({ uuid: null }));
			dispatch(
				moveWaypoint({
					stageUuid: stageProps.uuid,
					uuid,
					lat: event.latLng?.lat(),
					lng: event.latLng?.lng(),
				})
			);
		},
		[ type, stageProps.uuid, lat, lng ]
	);

	const onMarkerMouseOver = () => {
		if (marker) marker.setZIndex(10);
		dispatch(
			setHoveredWaypointUuid({
				uuid,
			})
		);
	};

	const onMarkerMouseOut = () => {
		if (marker) marker.setZIndex(2);
		dispatch(
			setHoveredWaypointUuid({
				uuid: null,
			})
		);
	};

	const removeAllListeners = () => {
		if (!marker) return;
		google.maps.event.clearInstanceListeners(marker);
	};

	const onMarkerDragStart = () => {
		if (!marker) return;
		google.maps.event.clearListeners(marker, 'mouseover');
		google.maps.event.clearListeners(marker, 'mouseout');
	};

	const onMarkerDrag = (event: google.maps.MapMouseEvent) => {
		dispatch(
			moveWaypoint({
				stageUuid: stageProps.uuid,
				uuid,
				lat: event.latLng?.lat(),
				lng: event.latLng?.lng(),
			})
		);
	};

	const debouncedOnMarkerDrag = debounce(onMarkerDrag, 25);

	const addEventListeners = () => {
		if (!marker) return;

		removeAllListeners();
		marker.addListener('click', onMarkerClick);
		marker.addListener('dragstart', onMarkerDragStart);
		marker.addListener('drag', debouncedOnMarkerDrag);
		marker.addListener('dragend', onMarkerDragEnd);
		marker.addListener('mouseover', onMarkerMouseOver);
		marker.addListener('mouseout', onMarkerMouseOut);
	};

	useEffect(() => {
		if (!marker) {
			setMarker(new google.maps.Marker());
		} else {
			addEventListeners();
		}

		// remove marker from map on unmount
		return () => {
			if (marker) {
				removeAllListeners();
				marker.setMap(null);
			}
		};
	}, [ marker, stageProps.uuid, type ]);

	useEffect(() => {
		if (marker) {
			marker.setOptions(getCustomMarkerProps());
		}
	}, [ marker, markerProps, type, isHovered ]);

	return null;
};

export default Marker;
