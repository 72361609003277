import { IconButton, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { translate } from '@@i18n/utils';
import SpeedIcon from '@mui/icons-material/Speed';
import { DISTANCE_UNIT } from '@@utils/constants';
import { setDistanceUnit } from '@@redux/actions/user';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { AppStoreProps } from '@@redux/index';
import CheckIcon from '@mui/icons-material/Check';

const DistanceUnitSwitchButton = () => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const theme = useTheme();
	const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);

	const currentDistanceUnit = useSelector((state: AppStoreProps) => state.user.distanceUnit);

	const openMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const closeMenu = () => setAnchorEl(null);

	return (
		<>
			<IconButton onClick={openMenu} color="inherit">
				<SpeedIcon />
			</IconButton>
			<Menu
				id="language-menu"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={Boolean(anchorEl)}
				onClose={closeMenu}
			>
				{Object.keys(DISTANCE_UNIT).map((key: string) => {
					const value: string = (DISTANCE_UNIT as any)[ key ];

					if (key !== 'DEFAULT') {
						return (
							<MenuItem
								key={key}
								onClick={() => {
									dispatch(setDistanceUnit({ unit: value as DISTANCE_UNIT }));
									closeMenu();
								}}
							>
								<>
									<CheckIcon
										sx={{
											opacity: currentDistanceUnit === value ? 1 : 0,
										}}
									/>
									<Typography sx={{ paddingLeft: theme.spacing(2) }}>
										{translate(intl, `components.AppBar.distance-unit-${value}`)}
									</Typography>
								</>
							</MenuItem>
						);
					} else {
						return null;
					}
				})}
			</Menu>
		</>
	);
};

export default DistanceUnitSwitchButton;
