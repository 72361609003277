import { translate } from '@@i18n/utils';
import { setGoogleMapsStatus } from '@@redux/actions/app';
import { AppStoreProps } from '@@redux/index';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { Box, Container, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Map from '..';

const MapWrapper = () => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const appbarHeight = useSelector((state: AppStoreProps) => state.app.appbarHeight || 0);
	const roadbookSidebarWidth = useSelector((state: AppStoreProps) => state.app.roadbookSidebarWidth);

	return (
		<Wrapper
			apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
			render={(status: Status) => {
				dispatch(setGoogleMapsStatus({ status }));

				return (
					<Typography variant="body1">
						{translate(intl, `components.Map.loadingStateTexts.${status}`)}
					</Typography>
				);
			}}
		>
			<Container
				disableGutters
				maxWidth={false}
				style={{
					paddingTop: appbarHeight,
				}}
			>
				<Box
					component="div"
					sx={{
						height: `calc(100vh - ${appbarHeight}px)`,
						width: `calc(100vw - ${roadbookSidebarWidth}px)`,
					}}
				>
					<Map />
				</Box>
			</Container>
		</Wrapper>
	);
};

export default MapWrapper;
