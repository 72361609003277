import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { createContext, FC, PropsWithChildren } from 'react';
import de_DE from '@@i18n/dist/de.flat.json';
import en_US from '@@i18n/dist/en.flat.json';
import { IMessageFileProps } from '@@i18n/utils';
import { setLocale } from '@@redux/actions/user';
import { SUPPORTED_LOCALES } from '@@utils/constants';
import { AppStoreProps } from '@@redux/index';

export const Context = createContext({
	language: SUPPORTED_LOCALES.DEFAULT as string,
});

const messages: IMessageFileProps = {
	'de-DE': de_DE,
	'en-US': en_US,
	'en-GB': en_US,
};

export const LanguageContextWrapper: FC<PropsWithChildren<any>> = (props: PropsWithChildren<any>) => {
	const dispatch = useDispatch();
	const currentLanguage: string = useSelector((state: AppStoreProps) => state.user.locale);
	let language = null;

	if (messages[ navigator.language ] !== undefined && currentLanguage === undefined) {
		language = navigator.language;
		dispatch(
			setLocale({
				locale: ((SUPPORTED_LOCALES as any)[ language ] as SUPPORTED_LOCALES) || SUPPORTED_LOCALES.DEFAULT,
			})
		);
	} else if (currentLanguage !== undefined) {
		language = currentLanguage;
	} else {
		language = SUPPORTED_LOCALES.DEFAULT;
	}

	return (
		<Context.Provider value={{ language }}>
			<IntlProvider messages={messages[ language ]} locale={language} defaultLocale={SUPPORTED_LOCALES.DEFAULT}>
				{props.children}
			</IntlProvider>
		</Context.Provider>
	);
};

export default LanguageContextWrapper;
