import { WaypointProps } from '@@redux/reducers/roadbook';
import { toRadians } from './toRadians';

/**
 * Haversine formula
 */
export const waypointDistance = (point1: WaypointProps, point2: WaypointProps) => {
	const R = 6378137;
	const dLat = toRadians(point2.lat - point1.lat);
	const dLong = toRadians(point2.lng - point1.lng);
	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(toRadians(point1.lat)) * Math.cos(toRadians(point2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	const d = R * c;

	return d / 1000; // returns the distance in km
};
