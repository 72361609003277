import { IReduxActionProps } from '@@redux/utils';
import { Status } from '@googlemaps/react-wrapper';

export enum APP_ACTIONS {
	SET_GOOGLE_MAPS_STATUS = 'SET_GOOGLE_MAPS_STATUS',
	TOGGLE_ROADBOOK_SIDEBAR = 'TOGGLE_ROADBOOK_SIDEBAR',
	OPEN_ROADBOOK_SIDEBAR = 'OPEN_ROADBOOK_SIDEBAR',
	CLOSE_ROADBOOK_SIDEBAR = 'CLOSE_ROADBOOK_SIDEBAR',
	SET_APPBAR_HEIGHT = 'SET_APPBAR_HEIGHT',
	SET_ROADBOOK_SIDEBAR_WIDTH = 'SET_ROADBOOK_SIDEBAR_WIDTH',
	SET_ROADBOOK_SIDEBAR_WIDTH_COOKIE = 'SET_ROADBOOK_SIDEBAR_WIDTH_COOKIE',
	TOGGLE_ROADBOOK_SIDEBAR_STAGE_ACCORDION_OPEN = 'TOGGLE_ROADBOOK_SIDEBAR_STAGE_ACCORDION_OPEN',
	TOGGLE_ROADBOOK_SIDEBAR_WAYPOINT_ACCORDION_OPEN = 'TOGGLE_ROADBOOK_SIDEBAR_WAYPOINT_ACCORDION_OPEN',
	TOGGLE_ROADBOOK_SIDEBAR_ROADBOOK_METADATA_ACCORDION_OPEN = 'TOGGLE_ROADBOOK_SIDEBAR_ROADBOOK_METADATA_ACCORDION_OPEN',
}

export const setGoogleMapsStatus = ({ status }: { status: Status }): IReduxActionProps => {
	return {
		type: APP_ACTIONS.SET_GOOGLE_MAPS_STATUS,
		payload: {
			status,
		},
	};
};

export const toggleRoadbookSidebar = (): IReduxActionProps => {
	return {
		type: APP_ACTIONS.TOGGLE_ROADBOOK_SIDEBAR,
	};
};

export const openRoadbookSidebar = (): IReduxActionProps => {
	return {
		type: APP_ACTIONS.OPEN_ROADBOOK_SIDEBAR,
	};
};

export const closeRoadbookSidebar = (): IReduxActionProps => {
	return {
		type: APP_ACTIONS.CLOSE_ROADBOOK_SIDEBAR,
	};
};

export const setAppbarHeight = ({ height }: { height: number }): IReduxActionProps => {
	return {
		type: APP_ACTIONS.SET_APPBAR_HEIGHT,
		payload: {
			height,
		},
	};
};

export const setRoadbookSidebarWidth = ({ width }: { width: number }): IReduxActionProps => {
	return {
		type: APP_ACTIONS.SET_ROADBOOK_SIDEBAR_WIDTH,
		payload: {
			width,
		},
	};
};

export const toggleRoadbookSidebarStageAccordionOpen = ({ open }: { open?: boolean }): IReduxActionProps => {
	return {
		type: APP_ACTIONS.TOGGLE_ROADBOOK_SIDEBAR_STAGE_ACCORDION_OPEN,
		payload: {
			open,
		},
	};
};

export const toggleRoadbookSidebarWaypointAccordionOpen = ({ open }: { open?: boolean }): IReduxActionProps => {
	return {
		type: APP_ACTIONS.TOGGLE_ROADBOOK_SIDEBAR_WAYPOINT_ACCORDION_OPEN,
		payload: {
			open,
		},
	};
};

export const toggleRoadbookSidebaRoadbookMetaDataAccordionOpen = ({ open }: { open?: boolean }): IReduxActionProps => {
	return {
		type: APP_ACTIONS.TOGGLE_ROADBOOK_SIDEBAR_ROADBOOK_METADATA_ACCORDION_OPEN,
		payload: {
			open,
		},
	};
};
