import { WaypointProps } from '@@redux/reducers/roadbook';
import { WAYPOINT_TYPE } from '@@utils/constants';
import { findLastIndex } from 'lodash';
import { waypointDistance } from './waypointDistance';

export interface SumDistancesProps {
	waypoints: WaypointProps[];
	untilWaypointType?: WAYPOINT_TYPE;
	untilWaypointIndex?: number;
	startIndex?: number;
}

export const sumDistances = ({
	waypoints,
	untilWaypointIndex,
	untilWaypointType,
	startIndex,
}: SumDistancesProps): number => {
	let result = 0;
	let loopStartIndex = waypoints.length - 1;
	let loopStopIndex = 0;

	if (startIndex !== undefined) loopStartIndex = startIndex;
	if (untilWaypointIndex !== undefined) loopStopIndex = untilWaypointIndex;
	if (untilWaypointType !== undefined) {
		loopStopIndex = findLastIndex(
			waypoints,
			(waypoint: WaypointProps, waypointIndex: number) =>
				waypoint.type === untilWaypointType && loopStartIndex > waypointIndex
		);
	}

	loopStopIndex = Math.max(0, loopStopIndex);
	loopStartIndex = Math.max(0, loopStartIndex);

	for (let index = loopStartIndex; index > loopStopIndex; index--) {
		const currentWaypoint = waypoints[ index ];
		const prevWaypoint = index > 0 ? waypoints[ index - 1 ] : waypoints[ 0 ];
		result += waypointDistance(currentWaypoint, prevWaypoint);
	}

	return result;
};
