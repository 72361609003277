export const GOOGLE_MAPS_READY_CHECK_TRIES = 10;
export const GOOGLE_MAPS_READY_CHECK_INTERVAL = 250;
export const GOOGLE_MAPS_SCRIPT_ID = 'RoadBookBuddyGoogleMapsJsApiScript';

export enum SUPPORTED_LOCALES {
	de_DE = 'de-DE',
	en_GB = 'en-GB',
	en_US = 'en-US',
	DEFAULT = 'de-DE',
}

export enum ROADBOOK_SIDE_BAR_SETTINGS {
	MIN_WIDTH = 300,
	DEFAULT_WIDTH = 600,
	COLOR_INDICATOR_WIDTH = 25,
}

export enum WAYPOINT_TYPE {
	WAYPOINT = 'WAYPOINT',
	SHAPING_POINT = 'SHAPING_POINT',
	START = 'START',
	FINISH = 'FINISH',
	HOME = 'HOME',
}

export const WAYPOINT_SIZES: {
	[key: string]: {
		width: number;
		height: number;
	};
} = {
	WAYPOINT: {
		width: 28,
		height: 28,
	},
	SHAPING_POINT: {
		width: 18,
		height: 18,
	},
	START: {
		width: 36,
		height: 36,
	},
	FINISH: {
		width: 36,
		height: 36,
	},
	HOME: {
		width: 36,
		height: 36,
	},
};

export type UUID = string | null | undefined;

export enum COOKIE_NAMES {
	ROADBOOK_SIDEBAR_WIDTH = 'roadbookbuddy_sidebarwidth',
}

export enum DRAG_STATES {
	START = 'START',
	DRAGGING = 'DRAGGING',
	END = 'END',
	DEFAULT = END,
}

export interface LatLngProps {
	lat: number;
	lng: number;
}

export enum DISTANCE_UNIT {
	MILES = 'MILES',
	KILOMETERS = 'KILOMETERS',
	DEFAULT = KILOMETERS,
}

export enum EARTH_RADIUS {
	KILOMETERS = 6370,
	MILES = 3958.8,
}
