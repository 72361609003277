import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { translate } from '@@i18n/utils';
import LanguageIcon from '@mui/icons-material/Language';
import { SUPPORTED_LOCALES } from '@@utils/constants';
import { setLocale } from '@@redux/actions/user';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { AppStoreProps } from '@@redux/index';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/system';

const LanguageSwitchButton = () => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const theme = useTheme();

	const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);

	const currentLanguage = useSelector((state: AppStoreProps) => state.user.locale);

	const openMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const closeMenu = () => setAnchorEl(null);

	return (
		<>
			<IconButton onClick={openMenu} color="inherit">
				<LanguageIcon />
			</IconButton>
			<Menu
				id="language-menu"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={Boolean(anchorEl)}
				onClose={closeMenu}
			>
				{Object.keys(SUPPORTED_LOCALES).map((key: string) => {
					const value: string = (SUPPORTED_LOCALES as any)[ key ];

					if (key !== 'DEFAULT') {
						return (
							<MenuItem
								key={key}
								onClick={() => {
									dispatch(setLocale({ locale: value as SUPPORTED_LOCALES }));
									closeMenu();
								}}
							>
								<>
									<CheckIcon
										sx={{
											opacity: currentLanguage === value ? 1 : 0,
										}}
									/>
									<Typography sx={{ paddingLeft: theme.spacing(2) }}>
										{translate(intl, `components.AppBar.language-${value}`)}
									</Typography>
								</>
							</MenuItem>
						);
					} else {
						return null;
					}
				})}
			</Menu>
		</>
	);
};

export default LanguageSwitchButton;
