import { StageProps } from '@@redux/reducers/roadbook';
import { Popover } from '@mui/material';
import { HexColorPicker } from 'react-colorful';
import { debounce } from '@@utils/functions/debounce';
import { Box } from '@mui/system';
import { ROADBOOK_SIDE_BAR_SETTINGS } from '@@utils/constants';
import { useDispatch } from 'react-redux';
import { setStageColor } from '@@redux/actions/roadbook';
import { useState } from 'react';

export interface ColorIconButtonProps {
	stage: StageProps;
}
const ColorIconButton = ({ stage }: ColorIconButtonProps) => {
	const dispatch = useDispatch();

	const [ popOverAnchorEl, setPopOverAnchorEl ] = useState<HTMLButtonElement | null>(null);

	const debouncedOnChange = debounce((color: string) => {
		dispatch(
			setStageColor({
				uuid: stage.uuid,
				color,
			})
		);
	}, 100);

	return (
		<>
			<Popover
				id={`color-popover-${stage.uuid}`}
				open={Boolean(popOverAnchorEl)}
				anchorEl={popOverAnchorEl}
				onClose={() => setPopOverAnchorEl(null)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<HexColorPicker color={stage.color} onChange={debouncedOnChange} />
			</Popover>
			<Box
				onClick={(event: React.MouseEvent<HTMLButtonElement>) => setPopOverAnchorEl(event.currentTarget)}
				component="button"
				sx={{
					position: 'absolute',
					top: 0,
					left: 0,
					height: '100%',
					width: ROADBOOK_SIDE_BAR_SETTINGS.COLOR_INDICATOR_WIDTH,
					minWidth: 0,
					backgroundColor: stage.color,
					opacity: stage.active ? 1 : 0.25,
					border: '0 none',
					cursor: 'pointer',
				}}
			/>
		</>
	);
};

export default ColorIconButton;
