import { StageProps } from '@@redux/reducers/roadbook';
import { useDispatch, useSelector } from 'react-redux';
import List from '@mui/material/List';
import { Button, Grid, Paper, useTheme } from '@mui/material';
import { addStage, setStageColor, setStageTitle, sortStagesDragNDrop } from '@@redux/actions/roadbook';
import { translate } from '@@i18n/utils';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import StageDataGridItem from './StageDataGridItem';
import { UUID } from '@@utils/constants';
import { restrictToVerticalAxis, restrictToWindowEdges, restrictToParentElement } from '@dnd-kit/modifiers';
import { AppStoreProps } from '@@redux/index';

const StageDataGrid = (): JSX.Element => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const theme = useTheme();
	const stages: StageProps[] = useSelector((state: AppStoreProps) => state.roadbook.stages);

	useEffect(() => {
		stages.forEach((stage: StageProps, stageIndex) => {
			if (stage.title === undefined || stage.title?.toString().length === 0) {
				dispatch(
					setStageTitle({
						uuid: stage.uuid,
						title: translate(intl, 'components.StageDataGrid.stageItem.defaultTitle', {
							number: (stageIndex + 1).toString(),
						}),
					})
				);
			}

			if (stage.color === undefined) {
				dispatch(setStageColor({ uuid: stage.uuid, color: theme.palette.primary.main }));
			}
		});
	}, [ stages ]);

	const onGridDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;

		let tmpStageUuids: UUID[] = stages.map((stage: StageProps) => stage.uuid);

		if ((active.id as UUID) !== (over?.id as UUID)) {
			const oldIndex = tmpStageUuids.indexOf(active.id);
			const newIndex = tmpStageUuids.indexOf(over?.id);
			tmpStageUuids = arrayMove(tmpStageUuids, oldIndex, newIndex);
		}

		dispatch(sortStagesDragNDrop({ uuids: tmpStageUuids }));
	};

	return (
		<Grid container rowSpacing={2}>
			{Array.isArray(stages) && stages.length > 0 && (
				<Grid item xs={12}>
					<Paper elevation={0} variant="outlined">
						<List sx={{ width: '100%', bgcolor: 'background.paper' }} disablePadding>
							<DndContext
								onDragEnd={onGridDragEnd}
								modifiers={[ restrictToParentElement, restrictToVerticalAxis, restrictToWindowEdges ]}
							>
								<SortableContext
									items={stages.map((stage: StageProps) => {
										return {
											id: stage.uuid?.toString() as string,
										};
									})}
									strategy={verticalListSortingStrategy}
								>
									{stages.map((stage: StageProps, stageIndex: number) => {
										return (
											<StageDataGridItem
												{...stage}
												key={stage.uuid}
												index={stageIndex}
												stageCount={stages.length}
											/>
										);
									})}
								</SortableContext>
							</DndContext>
						</List>
					</Paper>
				</Grid>
			)}
			<Grid item xs={12}>
				<Button
					onClick={() =>
						dispatch(
							addStage({
								title: translate(intl, 'components.StageDataGrid.stageItem.defaultTitle', {
									number: (stages.length + 1).toString(),
								}),
							})
						)
					}
					startIcon={<AddIcon />}
				>
					{translate(intl, 'components.StageDataGrid.addStageButtonText')}
				</Button>
			</Grid>
		</Grid>
	);
};

export default StageDataGrid;
