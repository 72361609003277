import { Buffer } from 'buffer';
import WaypointIcon from '!!raw-loader!@@icons/waypoint.svg';
import ShapingPointIcon from '!!raw-loader!@@icons/shapingpoint.svg';
import StartIcon from '!!raw-loader!@@icons/start.svg';
import FinishIcon from '!!raw-loader!@@icons/finish.svg';
import HomeIcon from '!!raw-loader!@@icons/home.svg';
import { WAYPOINT_TYPE } from '@@utils/constants';

export interface CreateSVGProps {
	svgData?: string;
	icon?: WAYPOINT_TYPE;
	width: number;
	height: number;
	foregroundColor?: string;
	foregroundColorHexCode?: string;
	backgroundColor?: string;
	backgroundColorHexCode?: string;
	toUrlString?: boolean;
	withSvgHeader?: boolean;
}

export const createSVG = ({
	svgData,
	icon,
	width = 24,
	height = 24,
	foregroundColor = 'white',
	foregroundColorHexCode = '#000000',
	backgroundColor = 'black',
	backgroundColorHexCode = '#FF0000',
	toUrlString = false,
	withSvgHeader = false,
}: CreateSVGProps): string => {
	if (!svgData && !icon) return '';

	let manipulatedSvgData = '';

	switch (icon) {
		case WAYPOINT_TYPE.WAYPOINT:
			manipulatedSvgData = WaypointIcon;
			break;
		case WAYPOINT_TYPE.SHAPING_POINT:
			manipulatedSvgData = ShapingPointIcon;
			break;
		case WAYPOINT_TYPE.START:
			manipulatedSvgData = StartIcon;
			break;
		case WAYPOINT_TYPE.FINISH:
			manipulatedSvgData = FinishIcon;
			break;
		case WAYPOINT_TYPE.HOME:
			manipulatedSvgData = HomeIcon;
			break;
		default:
			manipulatedSvgData = svgData as string;
			break;
	}

	if (foregroundColor) {
		manipulatedSvgData = manipulatedSvgData.replace(foregroundColorHexCode, foregroundColor);
	}
	if (backgroundColor) {
		manipulatedSvgData = manipulatedSvgData.replace(backgroundColorHexCode, backgroundColor);
	}
	let result = manipulatedSvgData;

	if (withSvgHeader) {
		result = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}px" height="${height}px" viewBox="0 0 24 24">${manipulatedSvgData}</svg>`;
	}

	if (toUrlString) result = 'data:image/svg+xml;base64,' + Buffer.from(result).toString('base64');

	return result;
};
