import { translate } from '@@i18n/utils';
import { Box, Drawer, Grid, IconButton, Paper, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import WaypointList from '@@components/AppShell/RoadbookSidebar/WaypointList';
import useResize from '@@utils/hooks/useResize';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
	setRoadbookSidebarWidth,
	toggleRoadbookSidebaRoadbookMetaDataAccordionOpen,
	toggleRoadbookSidebarStageAccordionOpen,
	toggleRoadbookSidebarWaypointAccordionOpen,
} from '@@redux/actions/app';
import { ROADBOOK_SIDE_BAR_SETTINGS, UUID } from '@@utils/constants';
import StageDataGrid from '@@components/AppShell/RoadbookSidebar/StageDataGrid';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RoadbookMetaData from './RoadbookMetaData';
import { RoadbookProps, StageProps } from '@@redux/reducers/roadbook';
import { AppStoreProps } from '@@redux/index';

const RoadbookSidebar = (): JSX.Element => {
	const intl = useIntl();
	const roadbookSidebarOpen = useSelector((state: AppStoreProps) => state.app.roadbookSidebarOpen);
	const roadbookSidebarWidth = useSelector((state: AppStoreProps) => state.app.roadbookSidebarWidth);
	const roadbookMetaDataAccordionOpen = useSelector(
		(state: AppStoreProps) => state.app.roadbookMetaDataAccordionOpen
	);
	const roadbookStageAccordionOpen = useSelector((state: AppStoreProps) => state.app.roadbookStageAccordionOpen);
	const roadbookWaypointAccordionOpen = useSelector(
		(state: AppStoreProps) => state.app.roadbookWaypointAccordionOpen
	);
	const appbarHeight = useSelector((state: AppStoreProps) => state.app.appbarHeight || 0);
	const theme = useTheme();
	const dispatch = useDispatch();

	const stages: StageProps[] = useSelector((state: AppStoreProps) => state.roadbook.stages);
	const activeStages: RoadbookProps['activeStages'] = useSelector(
		(state: AppStoreProps) => state.roadbook.activeStages
	);
	const selectedStageUuid: UUID = useSelector((state: AppStoreProps) => state.roadbook.selectedStageUuid);
	const selectedStageData: StageProps[] = stages.filter((stage: StageProps) => selectedStageUuid === stage.uuid);
	const selectedStageTitle: StageProps['title'] =
		Array.isArray(selectedStageData) && selectedStageData.length === 1 ? selectedStageData[ 0 ].title : undefined;

	const { width, enableResize } = useResize({
		minWidth: ROADBOOK_SIDE_BAR_SETTINGS.MIN_WIDTH,
		defaultWidth: roadbookSidebarWidth,
	});

	useEffect(() => {
		if (width !== roadbookSidebarWidth) {
			dispatch(setRoadbookSidebarWidth({ width }));
		}
	}, [ roadbookSidebarWidth, width ]);

	const elementSpacing = theme.spacing(2);

	return (
		<Drawer
			open={roadbookSidebarOpen}
			variant="persistent"
			anchor="right"
			PaperProps={{
				style: {
					width,
				},
			}}
		>
			<IconButton
				sx={{
					position: 'absolute',
					top: '50%',
					left: 0,
					transform: 'translate3d(-50%, -50%, 0)',
					cursor: 'col-resize',
					zIndex: theme.zIndex.drawer + 100,
				}}
				onMouseDown={enableResize}
			>
				<DragIndicatorIcon />
			</IconButton>
			<Grid
				item
				xs={12}
				sx={{
					height: appbarHeight,
					alignItems: 'center',
					display: 'flex',
					padding: `0 ${elementSpacing}`,
					boxShadow: theme.shadows[ 5 ],
					zIndex: theme.zIndex.drawer + 1,
					background: theme.palette.primary.light,
				}}
			>
				<Typography
					variant="h5"
					sx={{
						color: 'white',
					}}
				>
					{translate(intl, 'components.RoadbookSidebar.title')}
				</Typography>
			</Grid>
			<Box
				sx={{
					position: 'absolute',
					top: appbarHeight,
					height: `calc(100% - ${appbarHeight}px - ${elementSpacing})`,
					right: elementSpacing,
					left: elementSpacing,
					overflowX: 'hidden',
					overflowY: 'auto',
					paddingTop: elementSpacing,
					zIndex: theme.zIndex.drawer - 1,
				}}
			>
				<Paper elevation={0} variant="outlined" sx={{ marginBottom: theme.spacing(2) }}>
					<Accordion
						disableGutters
						expanded={roadbookMetaDataAccordionOpen}
						onChange={() => dispatch(toggleRoadbookSidebaRoadbookMetaDataAccordionOpen({}))}
					>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography variant="h6">{translate(intl, 'components.RoadbookMetaData.title')}</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<RoadbookMetaData />
						</AccordionDetails>
					</Accordion>
				</Paper>
				<Paper elevation={0} variant="outlined" sx={{ marginBottom: theme.spacing(2) }}>
					<Accordion
						disableGutters
						expanded={roadbookStageAccordionOpen}
						onChange={() => dispatch(toggleRoadbookSidebarStageAccordionOpen({}))}
					>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography variant="h6">
								{translate(intl, 'components.StageDataGrid.title', {
									enabledCount: activeStages.toFixed(),
									overallCount: stages.length.toFixed(),
								})}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<StageDataGrid />
						</AccordionDetails>
					</Accordion>
				</Paper>
				{Array.isArray(stages) && stages.length > 0 && activeStages > 0 && (
					<Paper elevation={0} variant="outlined" sx={{ marginBottom: theme.spacing(2) }}>
						<Accordion
							disableGutters
							expanded={roadbookWaypointAccordionOpen}
							onChange={() => dispatch(toggleRoadbookSidebarWaypointAccordionOpen({}))}
						>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<Typography variant="h6">
									{selectedStageTitle &&
										translate(intl, 'components.WaypointDataGrid.titleWithStageName', {
											stageName: selectedStageTitle,
										})}
									{!selectedStageTitle && translate(intl, 'components.WaypointDataGrid.title')}
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<WaypointList />
							</AccordionDetails>
						</Accordion>
					</Paper>
				)}
			</Box>
		</Drawer>
	);
};

export default RoadbookSidebar;
